import { Typography, useTheme } from '@mui/material';
import React from 'react';

export const KeyopsHeader1 = ({ children, ...props }) => {
  const { palette } = useTheme();
  return (
    <Typography
      variant={'h5'}
      component={'h1'}
      color={palette.keyops.black.main}
      {...props}
    >
      {children}
    </Typography>
  );
};

export const KeyopsHeader2 = ({ children, ...props }) => {
  const { palette } = useTheme();
  return (
    <Typography
      variant={'h6'}
      component={'h2'}
      color={palette.keyops.black.main}
      {...props}
    >
      {children}
    </Typography>
  );
};

export const KeyopsHeader3 = ({ children, ...props }) => {
  const { palette } = useTheme();
  return (
    <Typography
      variant={'body1'}
      component={'h3'}
      color={palette.keyops.black.main}
      fontSize={'1.125rem'} // 18px
      fontWeight={500}
      {...props}
    >
      {children}
    </Typography>
  );
};

export const KeyopsSubTitle1 = ({ children, ...props }) => {
  const { palette } = useTheme();
  return (
    <Typography variant={'body1'} color={palette.keyops.gray[600]} {...props}>
      {children}
    </Typography>
  );
};

export const KeyopsSubTitle2 = ({ children, ...props }) => {
  const { palette } = useTheme();
  return (
    <Typography variant={'body2'} color={palette.keyops.gray[600]} {...props}>
      {children}
    </Typography>
  );
};
