import React, { useState } from 'react';
import { Box, Button, List, useMediaQuery, useTheme } from '@mui/material';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';

import useNavConstants from '../hooks/useNavConstants';
import { hardwareAccelerationStyles } from '../../../styles/hardware-acceleration-styles';
import NavItem from './NavItem';

const DesktopSideNavbar = () => {
  const { breakpoints, palette } = useTheme();
  const isTab = useMediaQuery(breakpoints.only('sm'));
  const { bottomNavItems, topNavItems } = useNavConstants();

  const [sideNavOpen, setSideNavOpen] = useState(isTab ? false : true);
  const handleDrawerToggle = () => {
    setSideNavOpen((prev) => !prev);
  };

  const [sideNavHovered, setSideNavHovered] = useState(false);

  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'space-between'}
      flexShrink={0}
      bgcolor={palette.keyops.white.alpha800}
      borderRadius={'0px 16px 0px 0px'}
      onMouseEnter={() => setSideNavHovered(true)}
      onMouseLeave={() => setSideNavHovered(false)}
      sx={{
        width: 60,
        transition: 'width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
        overflowX: 'hidden',
        ...(sideNavOpen && {
          width: 280, // Expanded width when open
        }),
      }}
      data-testid="side-navbar"
    >
      <Box>
        <Box
          display={'flex'}
          flexDirection={'row-reverse'}
          sx={{
            ...hardwareAccelerationStyles,
            transition: 'all 0.25s ease',
          }}
          aria-label="drawer-toggle"
        >
          <Button
            aria-label="ChevronLeft"
            onClick={handleDrawerToggle}
            sx={{
              ...hardwareAccelerationStyles,
              minWidth: 0,
              bgcolor: palette.keyops.gray[100],
              p: 0.2,
              borderTopLeftRadius: 0,
              borderBottomRightRadius: 0,
              visibility: sideNavHovered || !sideNavOpen ? 'visible' : 'hidden',
              ':hover': {
                cursor: 'pointer',
                backgroundColor: palette.keyops.gray[200],
              },
            }}
          >
            {sideNavOpen ? (
              <FiChevronLeft fontSize={26} color={palette.keyops.gray[500]} />
            ) : (
              <FiChevronRight fontSize={26} color={palette.keyops.gray[500]} />
            )}
          </Button>
        </Box>
        <List sx={{ pr: 4 }}>
          {topNavItems.map((navItem) => (
            <NavItem key={navItem.name} item={navItem} isOpen={sideNavOpen} />
          ))}
        </List>
      </Box>
      <Box>
        <List sx={{ pr: 4 }}>
          {bottomNavItems.map((navItem) => (
            <NavItem key={navItem.name} item={navItem} isOpen={sideNavOpen} />
          ))}
        </List>
      </Box>
    </Box>
  );
};

export default DesktopSideNavbar;
