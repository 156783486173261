// External Dependencies
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FiCalendar, FiClipboard } from 'react-icons/fi';
import {
  Typography,
  ListItem,
  ListItemText,
  Stack,
  Box,
  useTheme,
  useMediaQuery,
  Divider,
} from '@mui/material/';

export interface CompletedEngagementRowProps {
  title: string;
  completedAt: string;
  isLastItem: boolean;
}

const CompletedEngagementRow = ({
  title,
  completedAt,
  isLastItem,
}: CompletedEngagementRowProps) => {
  const { t } = useTranslation();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <>
      <ListItem sx={{ px: { sm: 0 } }}>
        <ListItemText
          primary={
            <Typography
              component={'span'}
              color={theme.palette.keyops.black.main}
              noWrap={!isMobile}
            >
              {title}
            </Typography>
          }
          secondary={
            <Stack component={'span'} direction={'row'} gap={2} mt={1}>
              <Box component={'span'} display="inline-flex" alignItems="center">
                <FiClipboard color={theme.palette.keyops.blue.dark} />
                <Typography
                  variant="body2"
                  component={'span'}
                  color={theme.palette.keyops.black.main}
                  textTransform={'capitalize'}
                  sx={{ ml: 1 }}
                >
                  {t('general.survey')}
                </Typography>
              </Box>
              {completedAt && (
                <Box
                  component={'span'}
                  display="inline-flex"
                  alignItems="center"
                >
                  <FiCalendar color={theme.palette.keyops.blue.dark} />
                  <Typography
                    variant="body2"
                    component={'span'}
                    color={theme.palette.keyops.black.main}
                    textTransform={'capitalize'}
                    sx={{ ml: 1 }}
                  >
                    {t(
                      'dashboard.completedEngagementsSection.completedAtText',
                      {
                        completedDate: new Date(completedAt).toLocaleDateString(
                          'en-US',
                          {
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric',
                          }
                        ),
                      }
                    )}
                  </Typography>
                </Box>
              )}
            </Stack>
          }
        />
      </ListItem>
      {!isLastItem && <Divider />}
    </>
  );
};

export default CompletedEngagementRow;
