// External Dependencies
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Typography, Grid, Alert, AlertTitle } from '@mui/material';
import InputUnstyled from '@mui/base/InputUnstyled';

// Internal Dependencies
import { axiosCall } from '../../utils/api';
import { StyledInputRoot, StyledInputElement, Outline } from './styles.js';
import { RoundedButton } from '../../styles/index.js';
import Logo from '../../images/KeyOpsLogo.svg';
import PasswordInput from '../../components/PasswordInput';
import analytics from '../../utils/analytics';
import { TOAST_TYPES } from '../../utils/constants';
import ToastContext from '../../components/Toast/ToastContext';

const CustomInput = React.forwardRef(function CustomInput(props, ref) {
  const { components, ...other } = props;
  return (
    <InputUnstyled
      components={{
        Root: StyledInputRoot,
        Input: StyledInputElement,
        ...components,
      }}
      {...other}
      ref={ref}
    />
  );
});

CustomInput.propTypes = {
  /**
   * The components used for each slot inside the InputBase.
   * Either a string to use a HTML element or a component.
   * @default {}
   */
  components: PropTypes.shape({
    Input: PropTypes.elementType,
    Root: PropTypes.elementType,
    Textarea: PropTypes.elementType,
  }),
};

export default function ResetPassword() {
  const { t } = useTranslation();

  const { resetToken } = useParams();
  const { triggerToast } = React.useContext(ToastContext);

  console.log(resetToken);

  const [values, setValues] = React.useState({
    password: '',
    confirmPassword: '',
    hidden: true,
  });

  const navigate = useNavigate();

  const handleChange = async (event, field) => {
    await setValues({ ...values, [field]: event.target.value });

    if (event.keyCode === 13) {
      resetPassword();
    }
  };

  const resetPassword = async () => {
    const { password, confirmPassword } = values;

    if (password !== confirmPassword || password.trim() === '') {
      setValues({
        ...values,
        errorText: t('resetPassword.matchError'),
        hidden: false,
      });
      return;
    }
    try {
      await axiosCall(true, 'post', 'reset-password-post', false, false, {
        password,
        resetToken,
      });
      analytics.track('Password Reset Successful');
      triggerToast({
        type: TOAST_TYPES.SUCCESS,
        //ten minutes, we want it up for a while
        duration: 12000,
        message: t('resetPassword.successMessage'),
      });

      navigate(`/login`);
    } catch (e) {
      console.log(e);
      setValues({
        ...values,
        errorText: t('signIn.errorTitle'),
        hidden: false,
      });
    }
  };

  return (
    <Outline>
      <Grid container align="center">
        <Grid item xs={12}>
          <img style={{ height: 60 }} src={Logo} alt="KeyOps Logo" />
        </Grid>
        <Grid item xs={12} sx={{ p: 2.1 }}>
          <Typography>{t('resetPassword.title')}</Typography>
        </Grid>
        <Grid item xs={12} sx={{ pt: 1 }} hidden={values.hidden} align="left">
          <Alert severity="warning">
            <AlertTitle>{t('resetPassword.warningText')}</AlertTitle>
            <strong>{values.errorText}</strong>
          </Alert>
        </Grid>
        <Grid item xs={12} sx={{ mt: 1 }}>
          <PasswordInput
            id="reset-password-password"
            onChange={(e) => {
              handleChange(e, 'password');
            }}
          />

          <PasswordInput
            id="reset-password-confirm-password"
            placeholder="Confirm Password"
            onChange={(e) => {
              handleChange(e, 'confirmPassword');
            }}
          />
        </Grid>
        <Grid item xs={12} sx={{ pt: 2, pb: 3 }}>
          <RoundedButton variant="contained" fullWidth onClick={resetPassword}>
            {t('resetPassword.resetPasswordBtn')}
          </RoundedButton>
        </Grid>
      </Grid>
    </Outline>
  );
}
