// External Dependencies
import React from 'react';
import { Box, Stack, Typography, Zoom } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

// Internal Dependencies
import KeyOpsDatePicker from '../components/DatePicker';
import KeyOpsSingleSelect from '../components/SingleSelect';
import KeyOpsMultiSelect from '../components/MultiSelect';
import { CustomTooltip, FormTitle } from '../styles';
import { FORM_FIELDS } from './form-constants';
import {
  EthnicityOptions,
  OnboardingGenderOptions,
} from '../../../utils/enum-display';
import { useTranslation } from 'react-i18next';

//const appText = lang[navigator.language]?.onboarding.forms;
//const formText = appText.personalDetails;

const formFields = FORM_FIELDS.personalDetails;

export const PersonalDetails = ({ control, errors }) => {
  const { t } = useTranslation();
  return (
    <Stack spacing={4}>
      <Box display={'flex'} gap={2} alignItems={'center'}>
        <FormTitle>{t('onboarding.forms.personalDetails.title')}</FormTitle>
        <CustomTooltip
          placement="top"
          TransitionComponent={Zoom}
          title={
            <Typography variant={'body2'} textAlign={'center'}>
              {t('onboarding.forms.personalDetails.infoTooltip')}
            </Typography>
          }
          arrow
        >
          <InfoOutlinedIcon color="primary" />
        </CustomTooltip>
      </Box>
      <KeyOpsDatePicker
        fieldName={formFields.dateOfBirth.name}
        placeholder={formFields.dateOfBirth.label}
        rules={{
          required: t(`onboarding.forms.fieldErrors.required`, {
            field: formFields.dateOfBirth.label,
          }),
        }}
        control={control}
        errors={errors}
      />
      <KeyOpsSingleSelect
        fieldName={formFields.gender.name}
        placeholder={formFields.gender.label}
        defaultValue={''}
        rules={{
          required: t(`onboarding.forms.fieldErrors.required`, {
            field: formFields.gender.label,
          }),
        }}
        options={OnboardingGenderOptions}
        control={control}
        errors={errors}
      />
      <KeyOpsMultiSelect
        fieldName={formFields.ethnicity.name}
        placeholder={formFields.ethnicity.label}
        defaultValue={''}
        rules={{
          required: t(`onboarding.forms.fieldErrors.required`, {
            field: formFields.ethnicity.label,
          }),
        }}
        options={EthnicityOptions}
        control={control}
        errors={errors}
      />
    </Stack>
  );
};
