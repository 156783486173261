import React, { useMemo, useState } from 'react';
import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Divider,
  useTheme,
  Grid,
  Link,
  Tooltip,
  tooltipClasses,
  IconButton,
} from '@mui/material';
import { FiChevronDown, FiCopy } from 'react-icons/fi';
import { Trans, useTranslation } from 'react-i18next';

import PerkopolisLogo from '../../../images/logo-perkopolis.svg';
import ParkNFlyLogo from '../../../images/logo-parknfly.svg';
import WaiveTheWaitLogo from '../../../images/logo-waivethewait.svg';
import { KeyopsHeader3, KeyopsSubTitle1 } from '../../../components/Headers';
import {
  PARKNFLY,
  PARKNFLY_BOOKING,
  PERKOPOLIS,
  WAIVETHEWAIT,
} from '../../../utils/routes';
import { copyPopUpHandler } from '../../../utils/functions/common-utils';

interface RewardAccordionProps {
  rewardImg: JSX.Element;
  rewardText: JSX.Element | string;
  isDisplayed: boolean;
}

const RewardAccordion: React.FC<RewardAccordionProps> = ({
  rewardImg,
  rewardText,
  isDisplayed,
}) => {
  const { palette } = useTheme();

  if (isDisplayed !== undefined && isDisplayed) return null;
  return (
    <Grid item xs={12} sm={6} md={6} lg={4}>
      <Accordion
        elevation={0}
        sx={{
          border: `1px solid ${palette.keyops.gray[200]}`,
        }}
      >
        <AccordionSummary
          expandIcon={<FiChevronDown fontSize={32} />}
          sx={{
            '.MuiAccordionSummary-content': {
              my: 0.3,
            },
          }}
        >
          {rewardImg}
        </AccordionSummary>
        <AccordionDetails sx={{ pt: 0 }}>
          <Divider orientation={'horizontal'} flexItem />
          <Typography variant={'body2'} mt={2}>
            {rewardText}
          </Typography>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};

interface RewardsExclusivePerksSectionProps {
  isCanadian: boolean;
}

const RewardsExclusivePerksSection: React.FC<
  RewardsExclusivePerksSectionProps
> = ({ isCanadian }: RewardsExclusivePerksSectionProps) => {
  const { t } = useTranslation();

  const [copyPopUp, setCopyPopUp] = useState(false);

  const REWARDS = [
    {
      rewardName: 'Perkopolis',
      rewardImg: (
        <img
          src={PerkopolisLogo}
          alt="Perkopolis Logo"
          style={{ marginTop: 2, marginBottom: 2 }}
        />
      ),
      rewardText: (
        <Trans
          defaults={t('rewards.exclusivePerks.perkopolis')}
          components={{
            linkTag1: (
              <Link href={PERKOPOLIS} underline="none" target="_blank" />
            ),
            linkTag2: (
              <Link
                href={`mailto:rabitan@keyops.io?subject=Perkopolis%20ID&body=Hello,%0D%0A%0D%0APlease%20send%20me%20my%20Perkopolis%20ID.%0D%0A%0D%0AThank%20you.`}
                underline="none"
                target="_blank"
              />
            ),
            breakTag: <br />,
          }}
        />
      ),
    },
    {
      rewardName: 'Park N Fly',
      rewardImg: (
        <img
          src={ParkNFlyLogo}
          alt="Park N Fly logo"
          style={{ marginTop: 2, marginBottom: 2 }}
        />
      ),
      rewardText: (
        <Trans
          defaults={t('rewards.exclusivePerks.parkNFly')}
          components={{
            linkTag1: <Link href={PARKNFLY} underline="none" target="_blank" />,
            linkTag2: (
              <Link href={PARKNFLY_BOOKING} underline="none" target="_blank" />
            ),
            breakTag: <br />,
            tooltipTag: (
              <Tooltip
                open={copyPopUp}
                componentsProps={{
                  popper: {
                    sx: {
                      [`& .${tooltipClasses.arrow}`]: {
                        color: '#E2E8F0',
                      },
                      [`& .${tooltipClasses.tooltip}`]: {
                        backgroundColor: '#E2E8F0',
                        color: 'black',
                      },
                    },
                  },
                }}
                title="Copied!"
                arrow
                placement="top"
              >
                <IconButton
                  sx={{ padding: 0 }}
                  disableRipple={true}
                  onClick={() => copyPopUpHandler('1135949', setCopyPopUp, 750)}
                >
                  <FiCopy color={'#3376D1'} fontSize={'14px'} />
                </IconButton>
              </Tooltip>
            ),
          }}
        />
      ),
      isDisplayed: !isCanadian,
    },
    {
      rewardName: 'Waive The Wait',
      rewardImg: (
        <img
          src={WaiveTheWaitLogo}
          alt="Waive The Wait logo"
          style={{
            marginLeft: -18,
            marginTop: 2,
            marginBottom: 2,
          }}
        />
      ),
      rewardText: (
        <Trans
          defaults={t('rewards.exclusivePerks.waiveTheWait')}
          components={{
            linkTag1: (
              <Link href={WAIVETHEWAIT} underline="none" target="_blank" />
            ),
            linkTag2: (
              <Link
                href="mailto:tabassumpasha@waivethewait.com?subject=WaiveTheWait%20trial%20from%20KeyOps&body=Hello,%0D%0A%0D%0AI'm%20a%20KeyOps%20member,%20and%20I'd%20like%20to%20redeem%20my%202-week%20trial%20with%20WaiveTheWait.%0D%0A%0D%0AThank%20you."
                underline="none"
                target="_blank"
              />
            ),
            breakTag: <br />,
          }}
        />
      ),
    },
  ];

  const rewards = useMemo(
    () =>
      REWARDS.map((reward) => (
        <RewardAccordion
          key={reward.rewardName}
          rewardImg={reward.rewardImg}
          rewardText={reward.rewardText}
          isDisplayed={reward.isDisplayed}
        />
      )),
    [REWARDS]
  );

  return (
    <Box mt={3}>
      <KeyopsHeader3>{t('rewards.exclusivePerks.title')}</KeyopsHeader3>
      <KeyopsSubTitle1 mt={0.5}>
        {t('rewards.exclusivePerks.subtitle')}
      </KeyopsSubTitle1>
      <Box mt={3}>
        <Grid columnSpacing={2} rowSpacing={2} container>
          {rewards}
        </Grid>
      </Box>
    </Box>
  );
};

export default RewardsExclusivePerksSection;
