import React from 'react';
import {
  Box,
  List,
  Stack,
  SwipeableDrawer,
  Toolbar,
  useTheme,
} from '@mui/material';
import { FiMenu } from 'react-icons/fi';
import { useNavigate } from 'react-router';

import { KeyOpsIcon } from '../../../images/KeyOpsIcon';
import { DASHBOARD } from '../../../utils/routes';
import useNavConstants from '../hooks/useNavConstants';
import NavItem from './NavItem';

const MobileSideNavbar = ({
  openMobileSideNavDrawer,
  toggleMobileSideNavDrawer,
}: {
  openMobileSideNavDrawer: boolean;
  toggleMobileSideNavDrawer: (newOpen: boolean) => void;
}) => {
  const { palette } = useTheme();
  const navigate = useNavigate();
  const { bottomNavItems, topNavItems } = useNavConstants();

  return (
    <SwipeableDrawer
      id="mobile-side-navbar"
      data-testid="mobile-side-navbar"
      anchor={'left'}
      open={openMobileSideNavDrawer}
      onOpen={() => toggleMobileSideNavDrawer(true)}
      onClose={() => toggleMobileSideNavDrawer(false)}
      elevation={2}
      ModalProps={{
        slotProps: { backdrop: { sx: { backgroundColor: 'transparent' } } },
      }}
    >
      <Toolbar>
        <Stack direction="row" spacing={2} alignItems="baseline">
          <Box
            data-testid="drawer-menu"
            onClick={() => toggleMobileSideNavDrawer(false)}
          >
            <FiMenu fontSize={28} color={palette.keyops.blue.dark} />
          </Box>
          <Box
            width={33}
            height={33}
            data-testid="keyops-icon"
            onClick={() => navigate(DASHBOARD)}
          >
            <KeyOpsIcon />
          </Box>
        </Stack>
      </Toolbar>
      <Box
        display={'flex'}
        flexDirection={'column'}
        justifyContent={'space-between'}
        flexGrow={1}
      >
        <List>
          {topNavItems.map((navItem) => (
            <NavItem
              key={navItem.name}
              item={navItem}
              isOpen={openMobileSideNavDrawer}
              toggleDrawer={toggleMobileSideNavDrawer}
            />
          ))}
        </List>
        <List>
          {bottomNavItems.map((navItem) => (
            <NavItem
              key={navItem.name}
              item={navItem}
              isOpen={openMobileSideNavDrawer}
              toggleDrawer={toggleMobileSideNavDrawer}
            />
          ))}
        </List>
      </Box>
    </SwipeableDrawer>
  );
};

export default MobileSideNavbar;
