import React from 'react';
import { Button } from '@mui/material';
import { styled } from '@mui/system';

export const StyledButton = styled(Button)`
  text-transform: none;
`;

const SecondaryButton = ({ children, ...props }) => {
  return (
    <StyledButton variant="outlined" {...props}>
      {children}
    </StyledButton>
  );
};

export default SecondaryButton;
