import React, { useMemo } from 'react';
import {
  Box,
  Card,
  Divider,
  Stack,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { UserDto } from '@keyops-hcp/dtos';

import EarningsButton from './EarningsButton';
import RewardsButton from './RewardsButton';
import { getGreeting } from '../../../utils/functions/common-utils';
import { KeyopsHeader1 } from '../../../components/Headers';

type DashboardHeaderContentProps = {
  userData: UserDto;
  totalEarnings: number;
  totalRewardPoints: number;
  currentTier: string;
};

const getDisplayTitle = (userData: UserDto): string => {
  let displayName = '';
  if (userData.hcpType === 'physician') {
    displayName = `, Dr. ${userData?.lastName}`;
  } else {
    displayName = `, ${userData?.firstName}`;
  }
  return `${getGreeting()}${displayName}`;
};

const DashboardHeaderContent = ({
  userData,
  totalEarnings,
  totalRewardPoints,
  currentTier,
}: DashboardHeaderContentProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only('xs'));

  const displayTitle = useMemo(() => getDisplayTitle(userData), [userData]);

  return (
    <Stack
      direction={{ xs: 'column', sm: 'row' }}
      justifyContent={'space-between'}
      alignItems={{ xs: 'left', sm: 'center' }}
      spacing={2}
    >
      <Box>
        <KeyopsHeader1
          fontWeight={500}
          sx={{
            // The following css is to display the textOverflow in only 2 lines
            display: '-webkit-box',
            textOverflow: 'ellipsis',
            wordWrap: ' break-word',
            overflow: 'hidden',
            whiteSpace: 'pre-line',
            WebkitBoxOrient: 'vertical',
            // start showing ellipsis when 2nd line is reached
            WebkitLineClamp: 2,
          }}
        >
          {displayTitle}
        </KeyopsHeader1>
      </Box>
      {isMobile ? (
        <Card
          elevation={0}
          sx={{
            borderRadius: '8px',
            color: theme.palette.keyops.white.main,
          }}
        >
          <Stack
            py={{ xs: userData.hcpType !== 'physician' ? 0.5 : 2, sm: 4 }}
            sx={{
              px: 2,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
            }}
          >
            <EarningsButton
              hcpType={userData.hcpType}
              totalEarnings={totalEarnings}
            />
            {userData.hcpType === 'physician' && (
              <>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  minWidth={'50px'}
                >
                  <Divider
                    orientation="vertical"
                    style={{
                      height: '35px',
                      width: '1px',
                      color: theme.palette.keyops.gray[200],
                    }}
                  />
                </Box>
                <RewardsButton
                  tier={currentTier}
                  totalPoints={totalRewardPoints}
                />{' '}
              </>
            )}
          </Stack>
        </Card>
      ) : (
        <Box alignItems="center" sx={{ display: { xs: 'none', sm: 'flex' } }}>
          <EarningsButton
            hcpType={userData.hcpType}
            totalEarnings={totalEarnings}
          />
          {userData.hcpType === 'physician' && (
            <>
              <Divider
                orientation="vertical"
                flexItem
                sx={{
                  marginX: 3,
                  color: theme.palette.keyops.gray[200],
                }}
              />
              <RewardsButton
                tier={currentTier}
                totalPoints={totalRewardPoints}
              />
            </>
          )}
        </Box>
      )}
    </Stack>
  );
};

export default DashboardHeaderContent;
