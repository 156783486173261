import { EarningEngagementsResponseDto } from '../../dto/earnings.dto';

export const earningsTypeMap: Record<string, string> = {
  survey: 'Honorarium',
  referral: 'Referral bonus',
};

/*
 * getTotalEarnings calculates the total amount of the earnings passed as argument
 */
export const getTotalEarnings = (
  earnings: EarningEngagementsResponseDto[]
): number => {
  const totalEarnings = earnings.reduce((acc, currentEarning) => {
    return acc + currentEarning.amount;
  }, 0);

  return totalEarnings;
};

/*
 * cleanEarningsData returns the earnings with the right type
 */
export const cleanEarningsData = (
  earningsData: EarningEngagementsResponseDto[]
) => {
  return earningsData
    .map((earnings) => {
      return {
        ...earnings,
        type: earningsTypeMap[earnings.type],
      };
    })
    .sort((earningsA, earningsB) => {
      return (
        new Date(earningsB.completedAt).getMilliseconds() -
        new Date(earningsA.completedAt).getMilliseconds()
      );
    });
};
