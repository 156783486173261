// External Dependencies
import React, { useEffect, useContext } from 'react';
import { FiUser } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Grid,
  Typography,
  Avatar,
  Card,
  useTheme,
  Divider,
} from '@mui/material/';
import { UserDto } from '@keyops-hcp/dtos';

// Internal Dependencies
import { UserContext } from '../../UserContext';
import analytics from '../../utils/analytics';
import Loading from '../../components/Loading';
import PhysiciansProfileFields from './components/PhysiciansProfileFields';
import PharmacistsProfileFields from './components/PharmacistsProfileFields';
import NursesProfileFields from './components/NursesProfileFields';

// TODO: is it used?
const Profile = ({ backgroundColor }: { backgroundColor?: string }) => {
  const { t } = useTranslation();

  // 1. Get User data
  const theme = useTheme();
  const context = useContext(UserContext);
  const [userData, setUserData] = React.useState<UserDto>({} as UserDto);
  const [loadingData, setLoadingData] = React.useState(true);

  useEffect(() => {
    context.getData({
      setUserData: setUserData,
      setLoadingData: setLoadingData,
    });
  }, []);

  useEffect(() => {
    if (!loadingData) {
      analytics.track('Profile');
    }
  }, [loadingData]);

  const empty = Object.keys(userData).length > 0;

  // 2.
  if (loadingData) {
    // 2.1 Return load screen if data is not yet loaded
    return <Loading />;
  }

  // 2.2 Return Profile page component
  return (
    <Box
      sx={{
        pt: { xs: 3, sm: 7 },
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: backgroundColor ?? theme.palette.keyops.background,
      }}
    >
      <Avatar
        sx={{
          backgroundColor: '#C9D4E1',
          color: '#000000A3',
          width: '96px',
          height: '96px',
          fontSize: '40px',
          fontWeight: '600',
        }}
      >
        {empty && userData.firstName.length > 0
          ? userData.firstName[0]
          : t('general.na')}
        {empty && userData.lastName.length > 0
          ? userData.lastName[0]
          : t('general.na')}
      </Avatar>
      <Typography variant={'h6'} fontWeight={600} mt={2}>
        {userData.firstName} {userData.lastName}
      </Typography>
      <Typography variant={'body2'} mt={1}>
        {userData.email}
      </Typography>
      <Card
        variant={'outlined'}
        sx={{
          mx: '12%',
          mt: 4,
          px: 2.5,
          py: 2,
          borderRadius: 2,
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          sx={{ paddingLeft: 1.5, paddingBottom: 1.5 }}
        >
          <FiUser size={20} color={theme.palette.keyops.blue.dark} />
          <Box sx={{ width: 6 }} />
          <Typography align="center" fontWeight={500} fontSize={'14px'}>
            {t('profile.profileDetails.title')}
          </Typography>
        </Box>

        <Divider
          sx={{
            borderColor: theme.palette.keyops.gray[200],
            margin: '0 -1.5rem',
          }}
        />

        <Grid container spacing={1.5} p={1.5}>
          {userData.hcpType === 'physician' && (
            <PhysiciansProfileFields userData={userData} />
          )}
          {userData.hcpType === 'pharmacist' && (
            <PharmacistsProfileFields userData={userData} />
          )}
          {userData.hcpType === 'nurse' && (
            <NursesProfileFields userData={userData} />
          )}
        </Grid>
      </Card>
    </Box>
  );
};

export default Profile;
