import React, { MouseEvent } from 'react';
import { Box, Stack, Toolbar, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { FiMenu } from 'react-icons/fi';

import { KeyOpsIcon } from '../../../images/KeyOpsIcon';
import { DASHBOARD } from '../../../utils/routes';
import NotificationsButton from './notifications/NotificationsButton';
import { FEATURE_FLAGS } from '../../../utils/fe-feature-flags';

const MobileAppBar = ({
  toggleNotificationsUI,
  toggleMobileSideNavDrawer,
  areNotificationsUnread,
}: {
  toggleNotificationsUI: (event: MouseEvent<HTMLButtonElement>) => void;
  toggleMobileSideNavDrawer: (newOpen: boolean) => void;
  areNotificationsUnread: boolean;
}) => {
  const { palette } = useTheme();
  const navigate = useNavigate();

  return (
    <Toolbar data-testid="mobile-app-bar">
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
        margin={0}
        padding={0}
      >
        <Stack direction="row" spacing={2} alignItems="baseline">
          <Box
            data-testid="drawer-menu"
            onClick={() => toggleMobileSideNavDrawer(true)}
          >
            <FiMenu fontSize={28} color={palette.keyops.blue.dark} />
          </Box>
          <Box width={33} height={33} onClick={() => navigate(DASHBOARD)}>
            <KeyOpsIcon />
          </Box>
        </Stack>
        {FEATURE_FLAGS.NOTIFICATIONS_ENABLED && (
          <NotificationsButton
            toggleNotificationsUI={toggleNotificationsUI}
            areNotificationsUnread={areNotificationsUnread}
          />
        )}
      </Stack>
    </Toolbar>
  );
};

export default MobileAppBar;
