import React from 'react';
import {
  Typography,
  useTheme,
  Stack,
  useMediaQuery,
  Box,
} from '@mui/material/';
import { FiGift } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';

const ClaimButton = ({ tier, toggleClaimRewardPopup }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only('xs'));

  const { t } = useTranslation();

  return (
    <Stack
      data-testid="ClaimButtonId"
      justifyContent="center"
      alignItems="center"
      sx={{
        mx: { md: 0, lg: '5%', xl: '15%' },
        background: theme.palette.keyops.blue.light,
        borderRadius: '8px',
        margin: '16px auto 0 auto',
        padding: { xs: '16px 8px', sm: '16px' },
        flexDirection: 'row',
        cursor: 'pointer',
      }}
      onClick={toggleClaimRewardPopup}
    >
      <FiGift
        size={'24px'}
        style={{
          color: theme.palette.keyops.blue.medium,
          marginRight: '8px',
          marginBottom: '3px',
        }}
      />
      <Typography>
        <Box
          component="span"
          sx={{
            color: theme.palette.keyops.blue.main,
            fontSize: isMobile ? '15px' : '20px',
            fontWeight: 600,
          }}
        >
          {t('rewards.statusPanel.claimReward')}&nbsp;
        </Box>
        <Box
          component="span"
          sx={{
            color: theme.palette.keyops.gray[600],
            fontSize: isMobile ? '15px' : '20px',
            fontWeight: 500,
          }}
        >
          {t('rewards.statusPanel.claimRewardTier', {
            tier: tier.charAt(0).toUpperCase() + tier.slice(1),
          })}
        </Box>
      </Typography>
    </Stack>
  );
};
export default ClaimButton;
