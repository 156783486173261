import React, { useMemo } from 'react';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import HTMLOutput from '../../../components/HTMLOutput';
import { KeyopsHeader3 } from '../../../components/Headers';
import { t } from 'i18next';
import { HcpTypeOptions } from '../../../utils/enum-display';
import { formatSpecialties } from '../../../utils/functions/eligibility.utils';

const EngagementInfoDisplay = ({ engagement }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const allowedHcpType = HcpTypeOptions.getDisplay(engagement.hcpType);
  const allowedSpecialties = useMemo(() => {
    if (
      !engagement ||
      !engagement.allowedSpecialties ||
      engagement.hcpType !== 'physician'
    )
      return [];

    return formatSpecialties(engagement.allowedSpecialties);
  }, [engagement]);
  return (
    <Box mt={isMobile && 2} mb={isMobile && 0}>
      <KeyopsHeader3>{t('onboarding.engagementDetails.title')}</KeyopsHeader3>
      <Typography fontSize={isMobile && 15}>
        <p>
          {allowedSpecialties.length > 0 && allowedHcpType
            ? t(
                `onboarding.engagementDetails.eligibilityMessageWithSpecialties`,
                {
                  requiredHCPType: allowedHcpType,
                  requiredSpecialties: allowedSpecialties,
                }
              )
            : t(
                `onboarding.engagementDetails.eligibilityMessageNoSpecialties`,
                {
                  requiredHCPType: allowedHcpType,
                }
              )}
        </p>
        {engagement.hasOtherEligibilityCriteria && (
          <b>
            <p>
              {t(`onboarding.engagementDetails.otherEligibilityCriteriaIntro`)}
            </p>
            <HTMLOutput html={engagement.otherEligibilityCriteria} />
          </b>
        )}
        <p data-testid="payout-info">
          {t(`onboarding.engagementDetails.earn`, {
            payout: engagement.payoutValue,
            engagementTitle: engagement.title,
          })}
        </p>
        <p>{t(`onboarding.engagementDetails.notEligible`)}</p>
      </Typography>
    </Box>
  );
};

export default EngagementInfoDisplay;
