// External Dependencies
import React, { useEffect, useContext, useState } from 'react';
import { Box, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { UserDto } from '@keyops-hcp/dtos';

// Internal Dependencies
import { UserContext } from '../../UserContext';
import analytics from '../../utils/analytics';
import { EarningCard } from './components/EarningCard';
import { axiosCall } from '../../utils/api';
import EarningsTable from './components/EarningsTable';
import { EarningEngagementsResponseDto } from '../../dto/earnings.dto';
import Loading from '../../components/Loading';
import {
  cleanEarningsData,
  getTotalEarnings,
} from '../../utils/functions/earning-utils';
import { KeyopsHeader2, KeyopsSubTitle1 } from '../../components/Headers';
import i18next from '../../languages/i18n.config';

export const getEarningsEngagements = async (userId: string) => {
  try {
    const result = await axiosCall(false, 'get', `user/${userId}/get-earnings`);
    if (result.status >= 200) {
      return result.data.data;
    }
  } catch (error) {
    console.error(error);
  }
};

const Earnings = () => {
  const { palette, breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.only('xs'));

  // Get User data with corresponding Engagement data
  const context = useContext(UserContext);
  const [earningsData, setEarningsData] = useState<
    EarningEngagementsResponseDto[]
  >([] as EarningEngagementsResponseDto[]);
  const [userData, setUserData] = useState<UserDto>({} as UserDto);
  const [loadingData, setLoadingData] = useState(true);
  const [loadingEarnings, setLoadingEarnings] = useState(true);

  useEffect(() => {
    context.getData({
      setUserData: setUserData,
      setLoadingData: setLoadingData,
    });
  }, []);

  useEffect(() => {
    if (!loadingData) {
      analytics.track('Earnings');
    }
  }, [loadingData]);

  useEffect(() => {
    if (userData.id)
      try {
        setLoadingEarnings(true);
        const fetchData = async () => {
          const result = await getEarningsEngagements(userData.id.toString());
          if (result) {
            setEarningsData(result);
            setLoadingEarnings(false);
          }
        };
        fetchData();
      } catch (error) {
        console.error(error);
      }
  }, [userData.id]);

  return (
    <>
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        justifyContent={'space-between'}
        spacing={{ xs: 2 }}
      >
        <Box>
          <KeyopsHeader2>{i18next.t('earnings.title')}</KeyopsHeader2>
          <KeyopsSubTitle1>{i18next.t('earnings.subtitle')}</KeyopsSubTitle1>
        </Box>
        <Stack
          direction={{ xs: 'row-reverse', sm: 'column' }}
          spacing={{ xs: 2, sm: 0 }}
          alignItems={{ xs: 'center', sm: 'flex-end' }}
          justifyContent={{ xs: 'space-between', sm: 'flex-start' }}
          bgcolor={{ xs: palette.keyops.white.main }}
          px={{ xs: 2 }}
          py={{ xs: 0.5 }}
          borderRadius={1}
        >
          <Box>
            {loadingData || loadingEarnings ? (
              <Loading size={isMobile ? '16px' : '24px'} pt={0} />
            ) : (
              <Typography
                variant={isMobile ? 'h6' : 'h5'}
                component={'h1'}
                color={palette.keyops.blue.dark}
                fontWeight={600}
              >
                $
                {Intl.NumberFormat('currency').format(
                  getTotalEarnings(earningsData)
                )}
              </Typography>
            )}
          </Box>
          <Typography
            color={palette.keyops.gray[600]}
            fontWeight={500}
            textAlign={'right'}
          >
            {i18next.t('earnings.amountEarned')}
          </Typography>
        </Stack>
      </Stack>
      {/* mobile view */}
      <Box display={{ sm: 'none' }} mt={3}>
        {cleanEarningsData(earningsData).length === 0 && (
          <Typography
            variant={'body1'}
            color={palette.keyops.gray[500]}
            textAlign={'center'}
          >
            {i18next.t('earnings.earningsTableNoData')}
          </Typography>
        )}
        {cleanEarningsData(earningsData).map((earningEngagement, index) => {
          return (
            <EarningCard key={`${index}`} engagement={earningEngagement} />
          );
        })}
      </Box>
      {/* desktop view */}
      <Box display={{ xs: 'none', sm: 'block' }} mt={3}>
        <EarningsTable
          loadingEarnings={loadingEarnings}
          earningsData={cleanEarningsData(earningsData)}
        />
      </Box>
    </>
  );
};

export default Earnings;
