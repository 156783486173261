import React, { useState } from 'react';
import { useMatch, useNavigate } from 'react-router-dom';
import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { NavItemType } from '../hooks/useNavConstants';
import { useSurveyContext } from '../SurveyContext';
import ExitEngagementModal from './ExitEngagementModal';

interface NavItemProps {
  item: NavItemType;
  isOpen: boolean;
  toggleDrawer?: (newOpen: boolean) => void;
}

const NavItem = ({ item, isOpen, toggleDrawer }: NavItemProps): JSX.Element => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const match = useMatch(`${item.path}/*`);
  const { surveyIframeRendered, saveAndResumeActive } = useSurveyContext();
  const [openModal, setOpenModal] = useState(false);

  const [hovered, setHovered] = useState(false);

  const handlePathClick = (path: string) => {
    if (surveyIframeRendered && !saveAndResumeActive) {
      setOpenModal(true);
      return;
    }
    navigate(path);
    if (isMobile) {
      toggleDrawer(false);
    }
  };

  return (
    <ListItem
      key={item.name}
      sx={{
        display: 'block',
        py: 0.5,
      }}
    >
      <ExitEngagementModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        navigateFn={() => navigate(item.path)}
      />
      <ListItemButton
        onClick={
          item.path
            ? () => handlePathClick(item.path)
            : (event) => {
                item.action(event);
                if (isMobile) {
                  toggleDrawer(false);
                }
              }
        }
        onMouseEnter={() => setHovered(!isMobile)}
        onMouseLeave={() => setHovered(false)}
        sx={{
          minHeight: 48,
          justifyContent: 'center',
          borderRadius: 2,
          bgcolor: !!match && theme.palette.keyops.blue.light,
          '&:hover': {
            bgcolor: theme.palette.keyops.blue.dark,
            cursor: 'pointer',
          },
          pr: { xs: 8 },
        }}
      >
        <ListItemIcon
          data-testid="nav-item-icon"
          sx={{
            minWidth: 0,
            mr: isOpen && 3,
            justifyContent: 'center',
          }}
        >
          <item.icon
            color={
              hovered
                ? theme.palette.keyops.white.main
                : theme.palette.keyops.blue.dark
            }
            fontSize={20}
          />
        </ListItemIcon>
        {isOpen && (
          <ListItemText
            primary={item.name}
            sx={{
              color: hovered
                ? theme.palette.keyops.white.main
                : theme.palette.keyops.blue.dark,
            }}
          />
        )}
      </ListItemButton>
    </ListItem>
  );
};

export default NavItem;
