import { Country } from "./country.type";

export const Provinces = [
  "AB",
  "BC",
  "MB",
  "NL",
  "NB",
  "PE",
  "NS",
  "ON",
  "QC",
  "SK",
  "NT",
  "NU",
  "YT",
] as const;

export type Province = (typeof Provinces)[number];

export const States = [
  "AL",
  "AK",
  "AZ",
  "AR",
  "AS",
  "CA",
  "CO",
  "CT",
  "DE",
  "DC",
  "FL",
  "GA",
  "GU",
  "HI",
  "ID",
  "IL",
  "IN",
  "IA",
  "KS",
  "KY",
  "LA",
  "ME",
  "MD",
  "MA",
  "MI",
  "MN",
  "MS",
  "MO",
  "MT",
  "NE",
  "NV",
  "NH",
  "NM",
  "NJ",
  "NY",
  "NC",
  "ND",
  "NP",
  "OH",
  "OK",
  "OR",
  "PA",
  "PR",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VT",
  "VA",
  "VI",
  "WA",
  "WV",
  "WI",
  "WY",
] as const;

export type State = (typeof States)[number];

/**
 * just a union of everything
 */
export const StatesAndProvinces = [...States, ...Provinces];
/**
 * just a union of everything
 */
export type StateOrProvince = State | Province;

/**
 * states and provinces by country
 */
export const StatesAndProvincesByCountry: Partial<
  Record<Country, typeof States | typeof Provinces>
> = {
  CA: Provinces,
  US: States,
};
