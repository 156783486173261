import React, { useContext, useEffect, useMemo } from 'react';
import { IconType } from 'react-icons';
import {
  FiHome,
  FiLogOut,
  FiDollarSign,
  FiAward,
  FiMessageSquare,
  FiUser,
} from 'react-icons/fi';
import { UserDto } from '@keyops-hcp/dtos';
import { useMediaQuery, useTheme } from '@mui/material';

import {
  DASHBOARD,
  EARNINGS,
  REWARDS,
  LOGOUT,
  PROFILE,
} from '../../../utils/routes';
import { useContactUI } from '../../ContactUIContext';
import { UserContext } from '../../../UserContext';
import i18n from '../../../languages/i18n.config';

export interface NavItemType {
  name: string;
  icon: IconType;
  path: string;
  action?: (event: React.KeyboardEvent | React.MouseEvent) => void;
}

// top side-nav
const Dashboard: NavItemType = {
  name: i18n.t('navbar.navItems.dashboard'),
  icon: FiHome,
  path: DASHBOARD,
};
const Earnings: NavItemType = {
  name: i18n.t('navbar.navItems.earnings'),
  icon: FiDollarSign,
  path: EARNINGS,
};
const Rewards: NavItemType = {
  name: i18n.t('navbar.navItems.rewards'),
  icon: FiAward,
  path: REWARDS,
};
const Profile: NavItemType = {
  name: i18n.t('navbar.navItems.profile'),
  icon: FiUser,
  path: PROFILE,
};

// bottom side-nav
const ContactUs: NavItemType = {
  name: i18n.t('navbar.navItems.contactUs'),
  icon: FiMessageSquare,
  path: undefined,
};
const Logout: NavItemType = {
  name: i18n.t('navbar.navItems.logout'),
  icon: FiLogOut,
  path: LOGOUT,
};

const useNavConstants = () => {
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down('sm'));
  const { openContactUI } = useContactUI();
  const context = useContext(UserContext);
  const [userData, setUserData] = React.useState<UserDto>({} as UserDto);
  useEffect(() => {
    context.getData({
      setUserData,
    });
  }, []);

  const getNavItems = (type: 'desktop' | 'mobile') => {
    const commonNavItems = [Dashboard, Earnings];

    // top nav-items
    const typeSpecificTopNavItems = {
      physician: [...commonNavItems, Rewards],
      pharmacist: [...commonNavItems],
      nurse: [...commonNavItems],
    };

    if (type === 'mobile') {
      typeSpecificTopNavItems.physician.push(Profile);
      typeSpecificTopNavItems.pharmacist.push(Profile);
      typeSpecificTopNavItems.nurse.push(Profile);
    }

    // bottom nav-items
    const bottomNavItems = [{ ...ContactUs, action: openContactUI }, Logout];

    return {
      topNavItems: typeSpecificTopNavItems[userData.hcpType] || [],
      bottomNavItems,
    };
  };

  const navItems = useMemo(
    () => getNavItems(isMobile ? 'mobile' : 'desktop'),
    [userData, openContactUI]
  );

  return {
    topNavItems: navItems.topNavItems,
    bottomNavItems: navItems.bottomNavItems,
  };
};

export default useNavConstants;
