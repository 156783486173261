// External Dependencies
import React, { useMemo } from 'react';
import { Grid, Typography, Box, useMediaQuery } from '@mui/material/';
import { EngagementDto, UserDto } from '@keyops-hcp/dtos';
import { useTranslation } from 'react-i18next';

// Internal Dependencies
import ActiveEngagementCard from './ActiveEngagementCard';
import { getActiveInvitations } from '../../../../../utils/functions/invitation-utils';
import { theme } from '../../../../../theme';

type ActiveEngagementsSectionProps = {
  userData: UserDto;
  engagementData: EngagementDto[];
};

const ActiveEngagementsSection = ({
  userData,
  engagementData,
}: ActiveEngagementsSectionProps) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { t } = useTranslation();

  // Functions
  const activeEngagements = useMemo(
    () => getActiveInvitations(engagementData),
    [engagementData]
  );

  // Render
  const cards = activeEngagements?.map(
    ({ engagementId, engagement, state }) => {
      return (
        <ActiveEngagementCard
          key={engagementId} //unique key for each child
          engagementId={engagementId}
          title={engagement.title}
          payout={engagement.payoutValue}
          duration={engagement.estimatedTime}
          state={state}
        />
      );
    }
  );

  return (
    <>
      {activeEngagements.length > 0 ? (
        <>
          {isMobile && userData.hcpType === 'physician' ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                pb: '8px',
                mt: '-4px',
              }}
            >
              <Typography
                sx={{
                  fontSize: '12px',
                  fontWeight: 400,
                  color: theme.palette.keyops.gray[600],
                }}
              >
                {t(
                  'dashboard.activeEngagementsSection.engagementRewardsMobile'
                )}
              </Typography>
            </Box>
          ) : (
            <></>
          )}
          <Grid columnSpacing={3} rowSpacing={2} container>
            {cards}
          </Grid>
        </>
      ) : (
        <Typography
          sx={{
            color: theme.palette.keyops.gray[600],
          }}
        >
          {t('dashboard.activeEngagementsSection.emptyMessage')}
        </Typography>
      )}
    </>
  );
};

export default ActiveEngagementsSection;
