import React, { useState, createContext, useContext, useEffect } from 'react';
import { Languages } from '@keyops-hcp/constants';
import { useTranslation } from 'react-i18next';
import { keyOpsLSToken } from '../utils/constants';
import { axiosCall } from '../utils/api';
import analytics from '../utils/analytics';
import axios from 'axios';

const headers = { 'Content-Type': 'application/json' };
const withCredentials = true;

// Create the context
const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const { i18n } = useTranslation();

  const [authed, setAuthed] = useState(false);
  const [loading, setLoading] = useState(true);
  // Set to 'en' by default
  const [userLanguageSetting, setUserLanguageSetting] = useState(Languages[0]);

  useEffect(() => {
    if (i18n && i18n.language) {
      setUserLanguageSetting(i18n.language);
    }
  }, [i18n]);

  useEffect(() => {
    asyncLoginCheck().then((result) => {
      if (result) {
        setAuthed(true);
        setLoading(false);
      } else {
        setAuthed(false);
        setLoading(false);
      }
    });
  }, []);

  const login = async (email, password) => {
    const result = await asyncLoginCall(email, password, userLanguageSetting);

    if (result.status === 200) {
      localStorage.setItem(
        keyOpsLSToken,
        result.data.data.KeyOps__refreshToken
      );
      setAuthed(true);
    } else {
      analytics.track('Failed login', { email });
    }
    return result;
  };

  const setupPasswordAndLogin = async (resetToken, password) => {
    const result = await axiosCall(
      true,
      'post',
      'setup-password',
      false,
      false,
      {
        password,
        resetToken,
      }
    );

    if (result.status === 200) {
      console.log('The User has setup their password');
      localStorage.setItem(
        keyOpsLSToken,
        result.data.data.KeyOps__refreshToken
      );
      setAuthed(true);
      analytics.track('Password setup.');
    } else {
      analytics.track('Failed password setup');
    }
    return result;
  };

  const setupPasswordAndLoginWithGoogle = async (googleJWT) => {
    const result = await axiosCall(
      true,
      'post',
      'google-setup-password',
      false,
      false,
      {
        googleJWT,
      }
    );

    if (result.status === 200) {
      console.log('The User has setup their password');
      localStorage.setItem(
        keyOpsLSToken,
        result.data.data.KeyOps__refreshToken
      );
      setAuthed(true);
      analytics.track('Password setup with google.');
    } else {
      analytics.track('Failed password setup with google');
    }
    return result;
  };

  const loginWithGoogle = async (googleJWT) => {
    const result = await asyncLoginWithGoogleCall(
      googleJWT,
      userLanguageSetting
    );

    if (result.status === 200) {
      console.log('The User has logged in with google account');
      localStorage.setItem(
        keyOpsLSToken,
        result.data.data.KeyOps__refreshToken
      );
      setAuthed(true);
    } else {
      analytics.track('Login with google failed');
    }
    return result;
  };

  const logout = async () => {
    const result = await asyncLogoutCall();

    if (result.status === 200) {
      console.log('The User has logged out');
      analytics.track('logout');
      localStorage.removeItem(keyOpsLSToken); //temp solution
      setAuthed(false);
    }
  };

  const asyncLoginCheck = async () => {
    const refreshToken = localStorage.getItem(keyOpsLSToken);
    if (!refreshToken) return false;
    try {
      const results = await axios({
        headers,
        baseURL: process.env.REACT_APP_API_URL,
        method: 'post',
        url: 'validate-refresh-token',
        withCredentials,
        data: {
          Keyops__refreshToken: refreshToken,
        },
      });
      return !!results?.data?.data?.verified;
    } catch (e) {
      console.error(e);
      return false;
    }
  };

  const asyncLoginCall = async (email, password, userLanguageSetting) => {
    const results = await axiosCall(true, 'post', 'login', false, false, {
      email,
      password,
      userLanguageSetting,
    });

    return results;
  };

  const asyncLoginWithGoogleCall = async (googleJWT, userLanguageSetting) => {
    console.log(
      `asyncLoginWithGoogleCall > userLanguageSetting`,
      userLanguageSetting
    );
    document.cookie =
      'g_state=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    const results = await axiosCall(
      true,
      'post',
      'google-login',
      false,
      false,
      {
        googleJWT,
        userLanguageSetting,
      }
    );

    return results;
  };

  const asyncLogoutCall = async () => {
    const results = await axiosCall(true, 'post', 'logout');
    return results;
  };

  return (
    <AuthContext.Provider
      value={{
        authed,
        login,
        loginWithGoogle,
        logout,
        loading,
        setupPasswordAndLogin,
        setupPasswordAndLoginWithGoogle,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

// Finally creating the custom hook
export const useAuth = () => useContext(AuthContext);
