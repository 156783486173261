import { forOwn } from 'lodash';
import dayjs from 'dayjs';

import { DEFAULT_STRING_LENGTH } from '../constants';
import {
  CA_PHARMACIST_PRACTICE_SETTINGS,
  CA_PHARMACY_RETAIL_CHAINS,
  US_PHARMACIST_PRACTICE_SETTINGS,
  US_PHARMACIST_PRACTICE_SPECIALTIES,
  US_PHARMACY_RETAIL_CHAINS,
} from '../../pages/Onboarding/constants/practiceSettings';
import { PHARMACIST_TYPES } from '../../pages/Onboarding/constants/pharmacistTypes';

export const truncateString = (str, length = DEFAULT_STRING_LENGTH) =>
  str.length > length ? str.substring(0, length) + '...' : str;

export const validEmail = (email) => {
  const emailRegexp = new RegExp(
    /^(?=.{1,256})(?=.{1,64}@)[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/
  );

  return emailRegexp.test(email);
};

export const getUrl = (route, params = {}) => {
  forOwn(params, (val, key) => {
    route = route.replace(`:${key}`, val);
  });
  return route;
};

export const dateFormatter = (timestamp?: string): string | null => {
  if (!timestamp) {
    return null;
  }
  return new Date(timestamp).toLocaleString(['en-CA'], {
    formatMatcher: 'best fit',
    dateStyle: 'medium',
  });
};

export const copyPopUpHandler = (
  copy: string,
  setState: React.Dispatch<React.SetStateAction<boolean>>,
  timeout: number
) => {
  navigator.clipboard.writeText(copy);
  setState(true);
  setTimeout(() => {
    setState(false);
  }, timeout);
};

export const formatDateMDY = (dateString: string) => {
  return dayjs(dateString).format('MMMM D, YYYY');
};

export const getGreeting = () => {
  const currentTime = new Date().getHours();

  if (currentTime >= 0 && currentTime < 12) {
    return 'Good morning';
  } else if (currentTime >= 12 && currentTime < 17) {
    return 'Good afternoon';
  } else {
    return 'Good evening';
  }
};

export const getUSPharmacistPracticeSettingLabel = (practiceSetting) =>
  US_PHARMACIST_PRACTICE_SETTINGS.find(
    (option) => option.value === practiceSetting
  )?.label;

export const getUSPharmacistPracticeSpecialtyLabel = (practiceSpecialty) =>
  US_PHARMACIST_PRACTICE_SPECIALTIES.find(
    (option) => option.value === practiceSpecialty
  )?.label;

export const getUSPharmacistRetailChainLabel = (retailChain) =>
  US_PHARMACY_RETAIL_CHAINS.find((option) => option.value === retailChain)
    ?.label;

export const getCAPharmacistPracticeSettingLabel = (practiceSetting) =>
  CA_PHARMACIST_PRACTICE_SETTINGS.find(
    (option) => option.value === practiceSetting
  )?.label;

export const getCAPharmacistRetailChainLabel = (retailChain) =>
  CA_PHARMACY_RETAIL_CHAINS.find((option) => option.value === retailChain)
    ?.label;

export const getPharmacistTypeLabel = (type) =>
  PHARMACIST_TYPES.find((option) => option.value === type)?.label;
