import React, { useRef } from 'react';
import ReactPlayer from 'react-player';
import { Box, Container, Typography } from '@mui/material';
import { VideoDto } from '@keyops-hcp/dtos';

import { buildVimeoURL } from '../../utils/functions/video';
import { useTranslation } from 'react-i18next';

export const Video = ({ video }: { video: VideoDto }) => {
  const { t } = useTranslation();
  const playerRef = useRef<ReactPlayer | null>(null);

  return (
    <Container>
      {video ? (
        <Box padding={'24px'}>
          <Typography variant="h1" fontSize={'16px'} fontWeight={400}>
            {t('video.title')}
          </Typography>
          <Box display={'block'} width={'fit-content'} margin={'auto'}>
            <ReactPlayer
              url={buildVimeoURL(video.vimeoId)}
              ref={playerRef}
              controls
              onPause={() => console.log(`pausing video`)}
              onStart={() => console.log('starting video')}
            />
          </Box>
        </Box>
      ) : (
        <div>Loading data...</div>
      )}
    </Container>
  );
};
