// External Dependencies
import React from 'react';
import { FiX } from 'react-icons/fi';
import { Box, useTheme, Drawer, useMediaQuery } from '@mui/material/';

import BasicModal from '../../../components/BasicModal';
import ShareEngagement from '../../../components/ShareEngagement';

interface ReferralPopUpProps {
  referralPopUp: boolean;
  shareEngagementId: number | boolean;
  closeReferral: () => void;
}

const ReferralPopUp = ({
  referralPopUp,
  shareEngagementId,
  closeReferral,
}: ReferralPopUpProps): JSX.Element => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  if (isMobile) {
    return (
      <Drawer
        anchor={'bottom'}
        open={referralPopUp}
        onClose={closeReferral}
        PaperProps={{
          sx: {
            minHeight: '220px',
            padding: '16px 8px 32px 8px',
            borderRadius: '8px 8px 0 0',
          },
        }}
      >
        <Box
          sx={{
            borderRadius: '5px',
          }}
        >
          <FiX
            onClick={closeReferral}
            fontSize={24}
            style={{
              position: 'absolute',
              right: 5,
              top: 5,
              padding: '4px 4px 0px 0px',
            }}
          />
          <ShareEngagement
            engagementId={shareEngagementId}
            closeReferral={closeReferral}
          />
        </Box>
      </Drawer>
    );
  } else {
    return (
      <BasicModal open={referralPopUp} handleClose={closeReferral}>
        <ShareEngagement
          engagementId={shareEngagementId}
          closeReferral={closeReferral}
        />
      </BasicModal>
    );
  }
};

export default ReferralPopUp;
