import React, { MouseEvent } from 'react';
import { SwipeableDrawer } from '@mui/material';

import { DisplayNotification } from '../../../../utils/functions/notifications-utils';
import NotificationsRenderer from './NotificationsRenderer';

interface NotificationsDrawerProps {
  isOpen: boolean;
  toggleNotificationsUI: (event: MouseEvent<HTMLButtonElement>) => void;
  handleClose: () => void;
  loadingNotifications: boolean;
  notificationsError: string | null;
  notificationsList: DisplayNotification[];
}

const NotificationsDrawer = ({
  isOpen,
  toggleNotificationsUI,
  handleClose,
  loadingNotifications,
  notificationsError,
  notificationsList,
}: NotificationsDrawerProps) => {
  return (
    <SwipeableDrawer
      id={'notifications-drawer'}
      data-testid={'notifications-drawer'}
      anchor={'bottom'}
      open={isOpen}
      onOpen={toggleNotificationsUI}
      onClose={handleClose}
      sx={{
        display: { sm: 'none' },
      }}
      PaperProps={{
        sx: {
          borderRadius: '8px 8px 0 0',
          minHeight: 340,
          maxHeight: 600,
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
      <NotificationsRenderer
        handleClose={handleClose}
        notificationsList={notificationsList}
        loadingNotifications={loadingNotifications}
        notificationsError={notificationsError}
      />
    </SwipeableDrawer>
  );
};

export default NotificationsDrawer;
