// External Dependencies
import axios from 'axios';
import { LOGIN } from './routes';
import { keyOpsLSToken } from './constants';
import i18next from '../languages/i18n.config';

export const axiosCall = async (
  publicRoute,
  method,
  endpoint,
  pathParam,
  queryStringParams,
  queryBody
  // isOnboarding // without this the app can loop
) => {
  const withCredentials = true;
  let headers = { 'Content-Type': 'application/json' };
  // TODO, move this into a separate repo, include all the utils
  if (!publicRoute) {
    try {
      const accessTokenResults = await axios({
        headers,
        baseURL: process.env.REACT_APP_API_URL,
        method: 'post',
        url: 'access-token',
        withCredentials,

        data: {
          Keyops__refreshToken: localStorage.getItem(keyOpsLSToken),
        },
      });

      headers['keyops__accesstokens'] = accessTokenResults.data.accessToken;
    } catch (e) {
      //TODO: maybe?
      //localStorage.removeItem(keyOpsLSToken);
      window.location.href = LOGIN;
      return;
    }
  }

  // 2. Build the URL
  let url = endpoint;
  if (pathParam) {
    url += `/${pathParam}`;
  }

  // 3. Return the call
  let results;

  try {
    results = await axios({
      headers,
      baseURL: process.env.REACT_APP_API_URL,
      method,
      url,
      params: queryStringParams,
      data: queryBody,
      // This might need to change for private vs public
      withCredentials,
      credentials: 'include',
    });
  } catch (e) {
    const errorCode = e.response?.data?.errorCode ?? 'something_wrong';
    e.response.data.message = i18next.t(`apiErrorMessages.${errorCode}`, {
      contextData: e.response?.data?.contextData,
    });
    results = e;
  }
  return results;
};
