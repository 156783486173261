import React, { useMemo } from 'react';
import { Box, Link, useMediaQuery, useTheme } from '@mui/material';
import { FiX } from 'react-icons/fi';
import { Trans, useTranslation } from 'react-i18next';

import {
  KeyopsHeader3,
  KeyopsSubTitle1,
  KeyopsSubTitle2,
} from '../../../../components/Headers';
import Loading from '../../../../components/Loading';
import NotificationsEmptyState from './NotificationsEmptyState';
import NotificationsList from './NotificationsList';
import { DisplayNotification } from '../../../../utils/functions/notifications-utils';

const notificationsCutOff = 5;
interface NotificationsRendererProps {
  handleClose: () => void;
  loadingNotifications: boolean;
  notificationsError: string | null;
  notificationsList: DisplayNotification[];
  showAllNotifications?: boolean;
  toggleShowAllNotifications?: (open: boolean) => void;
}

const CenterContent: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => (
  <Box
    flexGrow={1}
    display={'flex'}
    flexDirection={'column'}
    alignItems={'center'}
    justifyContent={'center'}
    gap={2}
    mt={-4}
  >
    {children}
  </Box>
);

const NotificationsRenderer = ({
  handleClose,
  loadingNotifications,
  notificationsError,
  notificationsList,
  showAllNotifications,
  toggleShowAllNotifications,
}: NotificationsRendererProps) => {
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down('sm'));

  const { t } = useTranslation();

  const notificationsToShow = useMemo(() => {
    if (isMobile) return notificationsList;
    return showAllNotifications
      ? notificationsList
      : notificationsList.slice(0, notificationsCutOff);
  }, [showAllNotifications, notificationsList]);

  const unreadNotificationsCount = notificationsList.filter(
    (notification) => !notification.read
  ).length;

  const buildContent = () => {
    if (loadingNotifications)
      return (
        <CenterContent>
          <Loading size="5rem" pt={0} />
        </CenterContent>
      );
    if (notificationsError)
      return (
        <CenterContent>
          <KeyopsSubTitle2>{notificationsError}</KeyopsSubTitle2>
        </CenterContent>
      );
    if (notificationsList.length > 0) {
      return (
        <Box flexGrow={1} overflow={'auto'}>
          <NotificationsList
            list={notificationsToShow}
            handleClose={handleClose}
          />
        </Box>
      );
    }
    return (
      <CenterContent>
        <NotificationsEmptyState />
      </CenterContent>
    );
  };

  const buildFooter = () => {
    const allNotificationsText = t(
      'notifications.notificationsList.showingAllNotifications',
      {
        count: notificationsList.length,
      }
    );

    if (
      isMobile ||
      notificationsList.length <= notificationsCutOff ||
      showAllNotifications
    ) {
      return allNotificationsText;
    }

    return (
      <Trans
        defaults={t('notifications.notificationsList.showingNotifications', {
          count: notificationsCutOff,
        })}
        components={{
          buttonTag: (
            <Link
              component="button"
              underline="none"
              onClick={() => toggleShowAllNotifications(true)}
            />
          ),
        }}
      />
    );
  };

  return (
    <>
      <Box
        px={2.5}
        py={2}
        position="sticky"
        top={0}
        zIndex={1}
        bgcolor={'#fff'}
      >
        <KeyopsHeader3 display={'inline-block'}>
          {t('notifications.title')}
        </KeyopsHeader3>{' '}
        <KeyopsSubTitle1 display={'inline-block'}>
          {unreadNotificationsCount > 0 && `(${unreadNotificationsCount})`}
        </KeyopsSubTitle1>
        {isMobile && (
          <FiX
            data-testid="close-icon"
            fontSize={24}
            style={{
              position: 'absolute',
              right: 10,
              top: 10,
            }}
            onClick={handleClose}
          />
        )}
      </Box>
      {/* content here */}
      {isMobile ? (
        buildContent()
      ) : (
        <Box
          minHeight={notificationsList.length === 0 ? 280 : 240}
          maxHeight={480}
          width={450}
          display="flex"
          flexDirection="column"
          overflow="hidden"
        >
          {buildContent()}
        </Box>
      )}
      {/* footer here */}
      <Box
        px={2.5}
        py={2}
        position="sticky"
        bottom={0}
        zIndex={1}
        bgcolor={'#fff'}
        textAlign={'center'}
      >
        {notificationsList.length > 0 && (
          <KeyopsSubTitle2 fontSize={'0.75rem'}>
            {buildFooter()}
          </KeyopsSubTitle2>
        )}
      </Box>
    </>
  );
};

export default NotificationsRenderer;
