import React from 'react';
import { Typography, useTheme, Stack, Link } from '@mui/material/';
import { useTranslation } from 'react-i18next';

import { REWARDS } from '../../../utils/routes';
import RewardsBadge from '../../../components/RewardsBadge';

interface RewardsButtonProps {
  tier: string;
  totalPoints: number;
}

const RewardsButton: React.FC<RewardsButtonProps> = ({
  tier,
  totalPoints,
}: RewardsButtonProps) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Stack direction={'column'}>
      <Stack direction={'row'} alignItems={'center'}>
        <Link href={REWARDS} data-testid="earnings-link">
          <Typography
            display={'inline-flex'}
            sx={{
              pr: '8px',
              ':hover': {
                textDecoration: { xs: 'none', sm: 'underline' },
              },
              color: theme.palette.keyops.blue.dark,
              fontWeight: 600,
              fontSize: '24px',
            }}
          >
            {totalPoints.toLocaleString()}
          </Typography>
        </Link>
        <RewardsBadge tier={tier} width={'24px'} height={'24px'} />
      </Stack>
      <Typography
        sx={{
          color: theme.palette.keyops.gray[600],
          fontWeight: 500,
          fontSize: '14px',
        }}
        display={'inline-flex'}
      >
        {t('dashboard.rewards.rewardsButton')}
      </Typography>
    </Stack>
  );
};

export default RewardsButton;
