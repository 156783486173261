// External Dependencies
import React, { useMemo } from 'react';
import { Grid, Typography, useTheme, Box, useMediaQuery } from '@mui/material/';
import { EngagementDto, UserDto } from '@keyops-hcp/dtos';
import { useTranslation } from 'react-i18next';

// Internal Dependencies
import { getCompletedInvitationsWithReferrals } from '../../../../../utils/functions/invitation-utils';
import ReferralOpportunitiesCard from './ReferralOpportunitiesCard';

type ReferralOpportunitiesSectionProps = {
  userData: UserDto;
  openReferral: (engagementId: number) => void;
  engagementData: EngagementDto[];
};

const ReferralOpportunitiesSection = ({
  userData,
  openReferral,
  engagementData,
}: ReferralOpportunitiesSectionProps) => {
  // Config
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { t } = useTranslation();

  // Functions
  const engagementsWithReferrals = useMemo(
    () => getCompletedInvitationsWithReferrals(engagementData),
    [engagementData]
  );
  // Render
  const cards = engagementsWithReferrals?.map(
    ({ engagementId, engagement }) => {
      return (
        <ReferralOpportunitiesCard
          key={engagementId} //unique key for each child
          engagementId={engagementId}
          title={engagement.title}
          openReferral={openReferral}
          payout={engagement.referralPayout}
        />
      );
    }
  );

  return (
    <>
      {engagementsWithReferrals.length > 0 ? (
        <>
          {isMobile && userData.hcpType === 'physician' ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                pb: '8px',
                mt: '-4px',
              }}
            >
              <Typography
                sx={{
                  fontSize: '12px',
                  fontWeight: 400,
                  color: theme.palette.keyops.gray[600],
                }}
              >
                {t(
                  'dashboard.referralOpportunitiesSection.referralRewardsMobile'
                )}
              </Typography>
            </Box>
          ) : (
            <></>
          )}
          <Grid columnSpacing={3} rowSpacing={2} container>
            {cards}
          </Grid>
        </>
      ) : (
        <Typography
          sx={{
            color: theme.palette.keyops.gray[600],
          }}
        >
          {t('dashboard.referralOpportunitiesSection.emptyMessage')}
        </Typography>
      )}
    </>
  );
};

export default ReferralOpportunitiesSection;
