// External dependencies
import React, { useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import {
  Container,
  Grid,
  Box,
  useTheme,
  Typography,
  Link,
} from '@mui/material/';
import { Trans, useTranslation } from 'react-i18next';

// Internal Dependencies
import { UserContext } from '../../UserContext';
import Loading from '../../components/Loading';
import { BackToDashboardButton } from '../../components/BackToDashboardButton';
import { FiCheckCircle } from 'react-icons/fi';
import ShareEngagement from '../../components/ShareEngagement';
import { EngagementDto } from '@keyops-hcp/dtos';

const SurveyDone = () => {
  // 1. Setup State/Data
  const theme = useTheme();
  const context = useContext(UserContext);
  const { t } = useTranslation();

  const { engagementId } = useParams();
  const [engagementData, setEngagementData] = React.useState(
    {} as EngagementDto
  );
  const [loadingData, setLoadingData] = React.useState(true);

  // 3. Load data
  useEffect(() => {
    context.getInvitationData({
      setEngagementData: setEngagementData,
      setLoadingData: setLoadingData,
      engagementId: engagementId,
    });
    context.setData({}); // We want to fetch data again once survey is completed
  }, []);

  const { referralEnabled } = engagementData;

  // 3. Loader by Default
  if (loadingData) {
    // Return load screen if data is not yet loaded
    return <Loading />;
  }

  const referralSection = referralEnabled ? (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Box
        display="inline-flex"
        flexDirection="column"
        alignItems="center"
        sx={{
          backgroundColor: theme.palette.keyops.gray[100],
          borderRadius: '8px',
          paddingX: { xs: 2, md: 14 },
          paddingY: { xs: 2, md: 3 },
        }}
      >
        <ShareEngagement
          engagementId={parseInt(engagementId)}
          closeReferral={() => undefined}
          postEngagement={true}
        />
      </Box>
    </Box>
  ) : (
    <div />
  );

  return (
    <Container
      maxWidth="xl"
      sx={{
        pt: { xs: 0, md: 3 },
        pb: { xs: 2, md: 4 },
      }}
    >
      {/** Back to Dashboard button */}
      <BackToDashboardButton />

      <Box
        display="flex"
        paddingX={{ xs: '5%', md: '25%' }}
        paddingTop={{ xs: '20%', md: '8%' }}
        alignItems="center"
        flexDirection="column"
      >
        {/** Check Circle */}
        <Box
          sx={{
            width: { xs: 65, md: '114px' },
            height: { xs: 65, md: '114px' },
          }}
        >
          <FiCheckCircle color={theme.palette.keyops.teal} size="100%" />
        </Box>

        {/** Title */}
        <Typography
          fontWeight={600}
          fontSize={{ xs: 18, md: 24 }}
          paddingTop={2}
        >
          {t('engagement.surveyDoneTitle')}
        </Typography>

        {/** Body */}
        <Typography
          fontWeight={400}
          fontSize={16}
          align="center"
          paddingTop={1}
        >
          <Trans
            i18nKey="engagement.surveyDoneBody"
            components={[
              <Link
                key="earningsLink"
                underline="hover"
                href="/earnings"
                color={theme.palette.keyops.blue.main}
              >
                {t('earnings.title')}
              </Link>,
            ]}
          />
        </Typography>
      </Box>

      <Grid item xs={12} alignItems="center" sx={{ pt: 5, pb: 5 }}>
        {referralSection}
      </Grid>
    </Container>
  );
};

export default SurveyDone;
