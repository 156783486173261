import React, { useState, useEffect } from 'react';
import { Box, Stack, Typography, useTheme, useMediaQuery } from '@mui/material';
import { FiGift } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';

interface RewardConfirmationProps {
  modalHeight: number;
}

const RewardConfirmation = ({
  modalHeight,
}: RewardConfirmationProps): JSX.Element => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only('xs'));
  const { t } = useTranslation();

  const [height, setHeight] = useState(modalHeight);

  useEffect(() => {
    const timer = setTimeout(() => {
      setHeight(236); // Set the new height after a delay
    }, 100); // 100 milliseconds delay
    return () => clearTimeout(timer);
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: { xs: 'end', sm: 'start' },
        height: `${height}px`,
        transition: `height 0.5s ease`,
      }}
    >
      <Stack
        sx={{
          textAlign: 'center',
          alignItems: 'center',
          height: '220px',
          pt: '8px',
          pb: '8px',
          justifyContent: 'space-between',
          // Define the fade-in keyframes
          '@keyframes fadeIn': {
            '0%': { opacity: 0 },
            '100%': { opacity: 1 },
          },
          // Apply the animation
          animation: 'fadeIn 1s ease-in-out forwards',
        }}
      >
        <FiGift
          style={{
            color: theme.palette.keyops.blue.medium,
            width: isMobile ? '85px' : '120px',
            height: isMobile ? '85px' : '120px',
          }}
        />
        <Typography
          aria-label="share"
          sx={{
            fontSize: '18px',
            fontWeight: 600,
            color: theme.palette.keyops.gray[700],
          }}
        >
          {t('rewards.claimReward.greatChoice')}
        </Typography>
        <Typography
          sx={{
            fontSize: '16px',
            fontWeight: 400,
            color: theme.palette.keyops.gray[700],
          }}
        >
          {t('rewards.claimReward.ourTeam')}
        </Typography>
      </Stack>
    </Box>
  );
};

export default RewardConfirmation;
