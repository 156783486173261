// External Dependencies
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Card, List, Typography } from '@mui/material/';

// Internal Dependencies
import CompletedEngagementRow from './CompletedEngagementRow';
import { getCompletedInvitations } from '../../../../../utils/functions/invitation-utils';
import { theme } from '../../../../../theme';

// Constants
const COMPLETED_ENGAGEMENT_DEFAULT_LENGTH = 4;

const CompletedEngagementsSection = ({ engagementData }) => {
  const { t } = useTranslation();

  const [showAllCompletedEngagements, setShowAllCompletedEngagements] =
    useState(false);

  // Functions
  const toggleCompletedEngagements = () => {
    setShowAllCompletedEngagements((prevValue) => !prevValue);
  };
  const completedEngagements = useMemo(
    () => getCompletedInvitations(engagementData),
    [engagementData]
  );

  // Render
  const completedEngagementsToggleButton =
    completedEngagements?.length > COMPLETED_ENGAGEMENT_DEFAULT_LENGTH ? (
      <Box width={'100%'} display={'flex'} justifyContent={'center'} mt={3}>
        <Button
          variant="text"
          sx={{
            width: '100%',
            textTransform: 'none',
            ':hover': {
              backgroundColor: 'transparent',
            },
          }}
          onClick={toggleCompletedEngagements}
        >
          {showAllCompletedEngagements
            ? t('general.viewLess')
            : t('dashboard.completedEngagementsSection.viewAll')}
        </Button>
      </Box>
    ) : null;

  const cards = completedEngagements
    ?.sort((a, b) => {
      // Convert the completedAt values to milliseconds since epoch
      // Return the result in descending order by comparing the converted dates
      return (
        new Date(b.completedAt).getTime() - new Date(a.completedAt).getTime()
      );
    })
    ?.slice(0, showAllCompletedEngagements ? completedEngagements?.length : 4)
    .map(({ engagementId, completedAt, engagement }, index, array) => {
      const isLastItem = index === array.length - 1;
      return (
        <CompletedEngagementRow
          key={engagementId} //unique key for each child
          title={engagement.title}
          completedAt={completedAt}
          isLastItem={isLastItem}
        />
      );
    });

  return (
    <>
      {completedEngagements?.length > 0 ? (
        <Box>
          <List
            component={Card}
            variant="outlined"
            sx={{
              width: '100%',
              p: 0,
              bgcolor: '#ffffff',
              borderRadius: 2,
              border: { sm: 'none' },
            }}
          >
            {cards}
          </List>
          {completedEngagementsToggleButton}
        </Box>
      ) : (
        <Typography
          sx={{
            color: theme.palette.keyops.gray[600],
          }}
        >
          {t('dashboard.completedEngagementsSection.emptyMessage')}
        </Typography>
      )}
    </>
  );
};

export default CompletedEngagementsSection;
