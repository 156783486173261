// External Dependencies
import React, { createRef, useContext, useState } from 'react';
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import {
  Link,
  Typography,
  Grid,
  Alert,
  AlertTitle,
  Collapse,
  Divider,
  Box,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

// Internal Dependencies
import { StyledInputRoot, StyledInputElement, Outline } from './styles.js';
import { RoundedButton } from '../../styles/index.js';
import Logo from '../../images/KeyOpsLogo.svg';
import PasswordInput from '../../components/PasswordInput';
import { useAuth } from '../../higher-order-components/useAuth';
import GoogleButton from '../../components/GoogleButton';
import ToastContext from '../../components/Toast/ToastContext';
import { DASHBOARD } from '../../utils/routes';

const Login = () => {
  const { t } = useTranslation();

  const { login, loginWithGoogle } = useAuth();
  const [searchParams] = useSearchParams();
  const referralId = searchParams.get('referralId');
  const { triggerToast } = useContext(ToastContext);

  const googleButtonRef = createRef(null);

  const [values, setValues] = useState({
    email: '',
    password: '',
    showPassword: false,
    errorTitleText: '',
    errorSubTitleText: '',
    hidden: false,
  });

  const navigate = useNavigate();
  const location = useLocation();

  const handleChange = async (event, field) => {
    await setValues({ ...values, [field]: event.target.value, hidden: false });
    if (event.keyCode === 13) {
      signIn();
    }
  };

  const signIn = async (e) => {
    e.preventDefault();
    const email = values.email.trim();
    const password = values.password;
    const results = await login(email, password);
    const statusCode = results?.status;
    if (statusCode > 199 && statusCode < 300) {
      const redirectTo = location.state?.from?.pathname || DASHBOARD;
      navigate(
        referralId ? `${redirectTo}?referralId=${referralId}` : redirectTo,
        { replace: true }
      );
    } else {
      setValues({
        ...values,
        errorTitleText: t('signIn.errorTitle'),
        errorSubTitleTitleText: t('signIn.errorSubTitle'),
        hidden: true,
      });
    }
  };

  const handleLoginWithGoogle = async (responseJWT) => {
    const results = await loginWithGoogle(responseJWT);
    if (results?.status !== 200) {
      const type = 'error';
      const message =
        results?.response?.status === 401
          ? results?.response?.data?.message ?? t('signIn.noAccountFound')
          : t('general.errorText');
      triggerToast({
        type: type,
        message: message,
        duration: 5000,
      });
    } else {
      const redirectTo = location.state?.from?.pathname || DASHBOARD;
      navigate(
        referralId ? `${redirectTo}?referralId=${referralId}` : redirectTo,
        { replace: true }
      );
    }
  };

  return (
    <Outline>
      <form>
        <Grid container align="center">
          <Grid item xs={12}>
            <img
              style={{ height: 60 }}
              src={Logo}
              alt={t('general.keyOpsLogoAltText')}
            />
          </Grid>
          <Grid item xs={12} sx={{ p: 2.1 }}>
            <Typography>{t('signIn.welcome')}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Box textAlign={'center'} maxWidth={'400px'}>
              <StyledInputRoot>
                <StyledInputElement
                  placeholder={t('signIn.email')}
                  type={'text'}
                  value={values.email}
                  disableUnderline={true}
                  onChange={(e) => {
                    handleChange(e, 'email');
                  }}
                />
              </StyledInputRoot>
              <PasswordInput
                id="login-password"
                onChange={(e) => {
                  handleChange(e, 'password');
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Collapse
              in={values.hidden}
              sx={{ maxWidth: '400px', textAlign: 'left', pt: 1 }}
            >
              <Alert severity="warning">
                <AlertTitle>{values.errorTitleText}</AlertTitle>
                <strong>{values.errorSubTitleText}</strong>
              </Alert>
            </Collapse>
          </Grid>
          <Grid item xs={12} align="center" sx={{ mt: 0.5 }}>
            <Typography>
              <Link href="/forgot-password">{t('signIn.forgotPassword')}</Link>
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ mt: 2, mb: 3 }}>
            <RoundedButton
              type="submit"
              variant="contained"
              fullWidth
              onClick={signIn}
            >
              {t('general.signIn')}
            </RoundedButton>
          </Grid>
          <Divider sx={{ height: '100%', width: '100%' }}>
            <Typography variant="caption">{t('general.or')}</Typography>
          </Divider>
          <Grid item xs={12} sx={{ mt: 3 }}>
            <GoogleButton
              ref={googleButtonRef}
              callbackFunc={handleLoginWithGoogle}
              showOneTapDialog
            />
          </Grid>
          <Grid item xs={12} sx={{ mt: 4 }}>
            <Typography>
              {t('signIn.byContinuing')}{' '}
              <a href="https://www.keyops.io/terms-of-use">
                {t('signIn.termsOfUse')}
              </a>{' '}
              {t('general.and')}{' '}
              <a href="https://www.keyops.io/privacy-policy">
                {t('signIn.privacyPolicy')}
              </a>
              .
            </Typography>
          </Grid>
        </Grid>
      </form>
    </Outline>
  );
};

export default Login;
