import React, { useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Tabs,
  Tab,
  useTheme,
  useMediaQuery,
  Box,
  Typography,
  Stack,
} from '@mui/material/';
import { FiCircle } from 'react-icons/fi';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { EngagementDto, UserDto } from '@keyops-hcp/dtos';
import { useTranslation } from 'react-i18next';

import ActiveEngagementsSection from './components/ActiveEngagementsSection';
import CompletedEngagementsSection from './components/CompletedEngagementsSection';
import ReferralOpportunitiesSection from './components/ReferralOpportunitiesSection';
import { KeyopsHeader3 } from '../../../../components/Headers';

type EngagementSectionProps = {
  userData: UserDto;
  openReferral: (engagementId: number) => void;
  engagementData: EngagementDto[];
};

const EngagementSection = ({
  userData,
  openReferral,
  engagementData,
}: EngagementSectionProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { t } = useTranslation();

  const [tabValue, setTabValue] = useState(0);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  if (isMobile) {
    return (
      <>
        <Tabs
          value={tabValue}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          sx={{
            mt: 3,
            borderBottom: '1px solid gray',
            '& .MuiTab-root': {
              paddingLeft: 1.1,
              paddingRight: 1.1,
            },
          }}
        >
          <Tab
            sx={{ textTransform: 'none' }}
            label={t('dashboard.activeEngagementsSection.title')}
          />
          <Tab
            sx={{ textTransform: 'none' }}
            label={t('dashboard.referralOpportunitiesSection.title')}
          />
          <Tab
            sx={{ textTransform: 'none' }}
            label={t('dashboard.completedEngagementsSection.title')}
          />
        </Tabs>
        <Box mt={2}>
          {tabValue === 0 && (
            <ActiveEngagementsSection
              userData={userData}
              engagementData={engagementData}
            />
          )}
          {tabValue === 1 && (
            <ReferralOpportunitiesSection
              userData={userData}
              openReferral={openReferral}
              engagementData={engagementData}
            />
          )}
          {tabValue === 2 && (
            <CompletedEngagementsSection engagementData={engagementData} />
          )}
        </Box>
      </>
    );
  }

  return (
    <>
      {/* Active Engagements Section */}
      <Box>
        <Stack alignItems="center" direction="row" mb={3}>
          <KeyopsHeader3>
            {t('dashboard.activeEngagementsSection.title')}
          </KeyopsHeader3>

          {userData.hcpType === 'physician' && (
            <>
              <Box display="flex" justifyContent="center" minWidth="30px">
                <FiCircle
                  size={9}
                  fill={theme.palette.keyops.blue.main}
                  color={theme.palette.keyops.blue.main}
                />
              </Box>
              <Typography
                sx={{
                  fontSize: '14px',
                  fontWeight: 500,
                  color: theme.palette.keyops.gray[600],
                }}
              >
                {t('dashboard.rewards.engagementEarnDesktop')}
              </Typography>
            </>
          )}
        </Stack>
        <ActiveEngagementsSection
          userData={userData}
          engagementData={engagementData}
        />
      </Box>
      {/* Referral Opportunities Section*/}
      <Box mt={4}>
        <Stack alignItems="center" direction="row" mb={3}>
          <KeyopsHeader3>
            {t('dashboard.referralOpportunitiesSection.title')}
          </KeyopsHeader3>

          {userData.hcpType === 'physician' && (
            <>
              <Box display="flex" justifyContent="center" minWidth="30px">
                <FiCircle
                  size={9}
                  fill={theme.palette.keyops.blue.main}
                  color={theme.palette.keyops.blue.main}
                />
              </Box>
              <Typography
                sx={{
                  fontSize: '14px',
                  fontWeight: 500,
                  color: theme.palette.keyops.gray[600],
                }}
              >
                {t('dashboard.rewards.referralEarnDesktop')}
              </Typography>
            </>
          )}
        </Stack>
        <ReferralOpportunitiesSection
          userData={userData}
          openReferral={openReferral}
          engagementData={engagementData}
        />
      </Box>
      {/* Completed Engagements Section */}
      <Accordion
        disableGutters
        elevation={0}
        TransitionProps={{ unmountOnExit: true }}
        sx={{
          border: 'none',
          mt: 3,
          '&:before': {
            display: 'none',
          },
        }}
      >
        <AccordionSummary
          expandIcon={
            <ExpandMoreIcon
              sx={{
                color: 'black',
              }}
            />
          }
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{
            p: 0,
            display: 'inline-flex',
          }}
        >
          <KeyopsHeader3 mr={2}>
            {t('dashboard.completedEngagementsSection.title')}
          </KeyopsHeader3>
        </AccordionSummary>
        <AccordionDetails sx={{ p: 0 }}>
          <CompletedEngagementsSection engagementData={engagementData} />
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default EngagementSection;
