import React from 'react';
import { axiosCall } from './utils/api';
import PropTypes from 'prop-types';
import analytics from './utils/analytics';

export const UserContext = React.createContext();

export const UserContextProvider = ({ children }) => {
  const [data, setData] = React.useState({});

  const fetchDataFromDatabase = async () => {
    // Fetch data from database
    const userResults = await axiosCall(false, 'get', 'user');
    const engagementResults = await axiosCall(false, 'get', 'engagements');
    const referralResults = await axiosCall(
      false,
      'get',
      'referrals',
      `${userResults.data.data.dataValues.id.toString()}`
    );
    const rewardCurrentStatusResults = await axiosCall(
      false,
      'get',
      'user/rewards-current-status'
    );
    const rewardTransactionsResults = await axiosCall(
      false,
      'get',
      'user/rewards-transaction'
    );

    const result = {
      userData: userResults.data.data.dataValues,
      engagementData: engagementResults.data.data.invitations,
      referralData: referralResults.data.data.referrals,
      rewardPeriodData: rewardCurrentStatusResults.data.data.rewardPeriod,
      rewardTierData: rewardCurrentStatusResults.data.data.rewardTier,
      rewardTransactionData: rewardTransactionsResults.data.data,
    };

    return result;
  };

  const fetchCurrentData = async () => {
    // Return context data if existant
    if (Object.keys(data).length !== 0) {
      return data;
    }
    // Else, fetch data from database
    const result = await fetchDataFromDatabase();
    setData(result);
    return result;
  };

  const getData = async (props) => {
    const currentData = await fetchCurrentData();

    if ('setUserData' in props) {
      props.setUserData(currentData.userData);
    }
    if ('setEngagementData' in props) {
      props.setEngagementData(currentData.engagementData);
    }
    if ('setReferralData' in props) {
      props.setReferralData(currentData.referralData);
    }
    if ('setRewardTierData' in props) {
      props.setRewardTierData(currentData.rewardTierData);
    }
    if ('setRewardPeriodData' in props) {
      props.setRewardPeriodData(currentData.rewardPeriodData);
    }
    if ('setRewardTransactionData' in props) {
      props.setRewardTransactionData(currentData.rewardTransactionData);
    }
    if ('setLoadingData' in props) {
      props.setLoadingData(false);
    }
    analytics.identify(currentData.userData.id, currentData.userData);
  };

  const getInvitationData = async (props) => {
    const currentData = await fetchCurrentData();

    const invitationData = currentData.engagementData.filter(
      (invitation) => invitation.engagementId === parseInt(props.engagementId)
    )[0];

    if ('setUserData' in props) {
      props.setUserData(currentData.userData);
    }
    if ('setEngagementData' in props) {
      props.setEngagementData(
        invitationData ? invitationData.engagement : undefined
      );
    }
    if ('setInvitationData' in props) {
      props.setInvitationData(invitationData);
    }
    if ('setLoadingData' in props) {
      props.setLoadingData(false);
    }
  };

  const updateRewardsTierData = async () => {
    const rewardCurrentStatusResults = await axiosCall(
      false,
      'get',
      'user/rewards-current-status'
    );
    setData({
      ...data,
      rewardTierData: rewardCurrentStatusResults.data.data.rewardTier,
    });
  };

  return (
    <UserContext.Provider
      value={{
        data: data,
        setData: setData,
        getData: getData,
        getInvitationData: getInvitationData,
        updateRewardsTierData: updateRewardsTierData,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

UserContextProvider.propTypes = {
  message: PropTypes.string,
};
