// External Dependencies
import React, { useEffect, useContext, useState } from 'react';
import { Box, Divider, Typography, useTheme } from '@mui/material/';
import { UserDto } from '@keyops-hcp/dtos';
import { useTranslation } from 'react-i18next';

// Internal Dependencies
import { UserContext } from '../../UserContext';
import analytics from '../../utils/analytics';
import Loading from '../../components/Loading';
import RewardsStatusSection from './panels/RewardsStatusSection';
import ClaimRewardSection from './panels/ClaimRewardSection';
import RewardsExclusivePerksSection from './panels/RewardsExclusivePerksSection';
import WaysToEarn from './panels/WaysToEarn';
import PointsHistory from './panels/PointsHistory';
import NewTierNotificationPopUp from '../../components/NewTierNotificationPopUp';
import ClaimRewardPopup from './components/ClaimRewardPopup';
import { RewardOptions } from './data';
import { KeyopsHeader2 } from '../../components/Headers';

const RewardsHome = () => {
  const { palette } = useTheme();
  const context = useContext(UserContext);

  const { t } = useTranslation();

  const [loadingData, setLoadingData] = useState(true);
  const [rewardPeriodData, setRewardPeriodData] = useState({
    startDate: '',
    endDate: '',
    pointsTotal: 0,
  });
  const [rewardTierData, setRewardTierData] = useState({
    id: '',
    tier: '',
    claimed: false,
  });
  const [rewardTransactionData, setRewardTransactionData] = useState([]);
  const [userData, setUserData] = useState<UserDto>({} as UserDto);

  const [openClaimRewardsPopUp, setOpenClaimRewardsPopUp] = useState(false);
  const openClaimRewardsPopUpFn = () => {
    setOpenClaimRewardsPopUp(true);
  };

  useEffect(() => {
    context.getData({
      setUserData: setUserData,
      setLoadingData: setLoadingData,
      setRewardTierData,
      setRewardPeriodData,
      setRewardTransactionData,
    });
  }, []);

  useEffect(() => {
    if (!loadingData) {
      analytics.track('Rewards');
    }
  }, [loadingData]);

  if (loadingData) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Loading />
        <Typography
          sx={{
            pt: 3,
            fontWeight: 400,
            fontSize: { xs: '18px', md: '24px' },
          }}
        >
          {t('loading')}
        </Typography>
      </Box>
    );
  }

  return (
    <>
      <KeyopsHeader2>{t('rewards.statusPanel.title')}</KeyopsHeader2>
      <RewardsStatusSection
        tier={rewardTierData.tier}
        startDate={rewardPeriodData.startDate}
        resetDate={rewardPeriodData.endDate}
        totalRewards={rewardPeriodData.pointsTotal}
      />
      <ClaimRewardSection />
      <Divider flexItem sx={{ mt: 3, borderColor: palette.keyops.gray[100] }} />
      <RewardsExclusivePerksSection isCanadian={userData.country === 'CA'} />
      <WaysToEarn />
      <PointsHistory rewardTransactions={rewardTransactionData} />
      <NewTierNotificationPopUp
        rewardTierData={rewardTierData}
        openClaimRewardsPopUpFn={openClaimRewardsPopUpFn}
      />
      <ClaimRewardPopup
        tierId={rewardTierData?.id ?? ''}
        tier={rewardTierData.tier}
        open={openClaimRewardsPopUp}
        closePopUp={() => setOpenClaimRewardsPopUp(false)}
        rewards={RewardOptions[rewardTierData.tier]}
      />
    </>
  );
};

export default RewardsHome;
