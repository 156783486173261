import React from 'react';
import { Box, useTheme } from '@mui/material';
import { FiBell } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';

import { KeyopsHeader3, KeyopsSubTitle2 } from '../../../../components/Headers';

const NotificationsEmptyState = () => {
  const { palette } = useTheme();
  const { t } = useTranslation();

  return (
    <>
      <FiBell
        data-testid="notification-icon"
        fontSize={62}
        color={palette.keyops.blue.dark}
      />
      <Box textAlign={'center'}>
        <KeyopsHeader3>{t('notifications.emptyState.title')}</KeyopsHeader3>
        <KeyopsSubTitle2 color={palette.keyops.black.main}>
          {t('notifications.emptyState.subTitle')}
        </KeyopsSubTitle2>
      </Box>
    </>
  );
};

export default NotificationsEmptyState;
