import React from 'react';
import { Box, Button, Link, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { SURVEY_EMAIL_ADDRESS } from '../../../utils/constants';
import { KeyopsHeader2, KeyopsSubTitle1 } from '../../../components/Headers';
import BasicModal from '../../../components/BasicModal';

// TODO: add test
const SubmissionErrorModal = ({ openModal, handleClose }) => {
  const { palette } = useTheme();
  const { t } = useTranslation();

  return (
    <BasicModal open={openModal} handleClose={handleClose}>
      <Box data-testid="error-create-account-modal">
        <KeyopsHeader2 fontWeight={600}>
          {t('onboarding.errorModal.title')}
        </KeyopsHeader2>
        <KeyopsSubTitle1 mt={4}>
          {t('onboarding.errorModal.bodyText1')}
        </KeyopsSubTitle1>
        <KeyopsSubTitle1 mt={2}>
          {t('onboarding.errorModal.bodyText2')}
          <Link
            href={`mailto:${SURVEY_EMAIL_ADDRESS}`}
            color={palette.keyops.gray[600]}
          >
            {t('onboarding.errorModal.supportLinkText')}
          </Link>
          .
        </KeyopsSubTitle1>
        <Button
          variant="contained"
          onClick={handleClose}
          sx={{
            float: 'right',
            fontSize: 16,
            textTransform: 'capitalize',
            boxShadow: 'none',
            mt: 3,
          }}
        >
          {t('general.okay')}
        </Button>
      </Box>
    </BasicModal>
  );
};

export default SubmissionErrorModal;
