import { NotificationDto } from '@keyops-hcp/dtos';
import { axiosCall } from '../utils/api';
import {
  ALL_NOTIFICATIONS,
  UPDATE_NOTIFICATIONS_READ_STATUS,
} from '../utils/api-routes';
import i18next from '../languages/i18n.config';

export const getAllNotifications = async (): Promise<NotificationDto[]> => {
  try {
    const results = await axiosCall(false, 'get', ALL_NOTIFICATIONS, false, {});
    if (results.status === 200) {
      return results.data.data || [];
    }
    throw new Error(
      results?.data.message || i18next.t('api.notifications.errorFetching')
    );
  } catch (error) {
    console.log(error);
    throw new Error(
      error?.message || i18next.t('api.notifications.errorFetching')
    );
  }
};

export const updateReadStatusCall = async (
  unreadNotificationsIds: string[]
): Promise<void> => {
  try {
    const results = await axiosCall(
      false,
      'patch',
      UPDATE_NOTIFICATIONS_READ_STATUS,
      false,
      undefined,
      { ids: unreadNotificationsIds }
    );
    if (results?.status !== 200) {
      throw new Error(
        results?.data.message ||
          i18next.t('api.notifications.errorUpdatingReadStatus')
      );
    }
  } catch (error) {
    throw new Error(
      error?.message || i18next.t('api.notifications.errorUpdatingReadStatus')
    );
  }
};
