import * as React from 'react';
import { t } from 'i18next';
import HTMLOutput from '../../../components/HTMLOutput';
import SecondaryButton from '../../../components/SecondaryButton';
import PrimaryButton from '../../../components/PrimaryButton';
import BasicModal from '../../../components/BasicModal';
import { KeyopsHeader3 } from '../../../components/Headers';
import { useNavigate } from 'react-router';
import { DASHBOARD } from '../../../utils/routes';
import { Stack, Typography } from '@mui/material';

export const EngagementEligibilityConfirmation = ({
  startOpen,
  otherEligibilityCriteria,
  declineEngagement,
}: {
  startOpen: boolean;
  otherEligibilityCriteria: string;
  declineEngagement: () => void;
}) => {
  const [open, setOpen] = React.useState(startOpen);
  const navigate = useNavigate();

  const handleClose = () => {
    setOpen(false);
    navigate(DASHBOARD);
  };
  const handleDecline = () => {
    setOpen(false);
    declineEngagement();
  };
  const handleAccept = () => {
    setOpen(false);
  };

  return (
    <BasicModal open={open} handleClose={handleClose}>
      <KeyopsHeader3>
        {t('engagement.eligibilityConfirmation.title')}
      </KeyopsHeader3>
      <Typography>
        {t('engagement.eligibilityConfirmation.intro')}
        <HTMLOutput html={otherEligibilityCriteria} />
        {t('engagement.eligibilityConfirmation.outro')}
      </Typography>
      <Stack
        direction={{ xs: 'column-reverse', sm: 'row' }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
        justifyContent="space-between"
      >
        <SecondaryButton onClick={handleDecline}>
          {t('engagement.eligibilityConfirmation.decline')}
        </SecondaryButton>
        <PrimaryButton onClick={handleAccept}>
          {t('engagement.eligibilityConfirmation.accept')}
        </PrimaryButton>
      </Stack>
    </BasicModal>
  );
};
