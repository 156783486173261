import React from 'react';
import { Box, Button, Drawer, useTheme } from '@mui/material';
import { FiChevronRight, FiUser } from 'react-icons/fi';
import Profile from '../../../pages/Profile';

const drawerWidth = 320;

interface ProfileDrawerProps {
  isProfileDrawerOpen: boolean;
  toggleProfileDrawer: () => void;
}

const ProfileDrawer = ({
  isProfileDrawerOpen,
  toggleProfileDrawer,
}: ProfileDrawerProps): JSX.Element => {
  const theme = useTheme();

  return (
    <Drawer
      anchor="right"
      open={isProfileDrawerOpen}
      onClose={toggleProfileDrawer}
      PaperProps={{
        style: {
          width: drawerWidth,
        },
        elevation: 0,
        sx: { backgroundColor: theme.palette.keyops.gray[50] },
      }}
      data-testid="profile-drawer"
    >
      <Box
        sx={{
          pt: 3,
          pr: 3,
          backgroundColor: theme.palette.keyops.gray[50],
          display: 'flex',
          justifyContent: 'right',
        }}
      >
        <Button
          size="large"
          onClick={toggleProfileDrawer}
          sx={{
            backgroundColor: theme.palette.keyops.background,
            textTransform: 'none',
            borderRadius: '26px',
            border: `1px solid ${theme.palette.keyops.blue.dark}`,
            color: theme.palette.keyops.blue.dark,
            '&:hover': {
              backgroundColor: theme.palette.keyops.blue.dark,
              color: theme.palette.keyops.background,
            },
          }}
          data-testid="profile-drawer-close-button"
        >
          <FiChevronRight
            style={{
              fontSize: 20,
              marginLeft: '-8px',
            }}
          />
          <FiUser size={20} />
        </Button>
      </Box>
      <Profile backgroundColor={theme.palette.keyops.gray[50]} />
    </Drawer>
  );
};

export default ProfileDrawer;
