import React from 'react';
import { Box, Button, Typography, useTheme } from '@mui/material';
import { FiAlertCircle } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';

import BasicModal from '../../../components/BasicModal';

const ExitEngagementModal = ({
  openModal,
  setOpenModal,
  navigateFn,
}: {
  openModal: boolean;
  setOpenModal: (value: boolean) => void;
  navigateFn: () => void;
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const handleNavigate = () => {
    setOpenModal(false);
    navigateFn();
  };
  return (
    <BasicModal open={openModal} handleClose={() => setOpenModal(false)}>
      <Box
        data-testid="exit-engagement-modal"
        display="flex"
        flexDirection="column"
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          paddingTop={2}
        >
          <FiAlertCircle
            color={theme.palette.keyops.blue.medium}
            fontSize={75}
          />
          <Typography fontSize="18px" fontWeight={600} paddingY={1}>
            {t('dashboard.exitEngagementModal.leaveSurvey')}
          </Typography>
          <Typography fontSize="16px" fontWeight={400}>
            {t('dashboard.exitEngagementModal.progressNotSaved')}
          </Typography>
        </Box>
        <Box
          display="inline-flex"
          flexDirection="row-reverse"
          paddingTop={4}
          gap={1.5}
        >
          <Button
            variant="contained"
            sx={{
              textTransform: 'none',
              paddingX: 2,
              height: '40px',
            }}
            onClick={() => setOpenModal(false)}
          >
            <Typography fontSize="16px" fontWeight={600}>
              {t('dashboard.exitEngagementModal.stay')}
            </Typography>
          </Button>
          <Button
            variant="outlined"
            sx={{
              border: `1px solid ${theme.palette.keyops.blue[500]}`,
              borderRadius: '6px',
              textTransform: 'none',
              height: '40px',
              paddingX: 2,
            }}
            onClick={handleNavigate}
          >
            <Typography fontSize="16px" fontWeight={600}>
              {t('dashboard.exitEngagementModal.leave')}
            </Typography>
          </Button>
        </Box>
      </Box>
    </BasicModal>
  );
};

export default ExitEngagementModal;
