// Onboarding
export const ONBOARDING_ROOT = '/onboarding';
export const GOOGLE_ONBOARDING_ROOT = '/google-onboarding';
export const VERIFY_ONBOARDING_LINK = '/onboarding/verifyOnboardingLink';
export const VALIDATE_EMAIL = '/user/validEmail';
export const VALIDATE_WORK_EMAIL = '/user/validWorkEmail';
export const VALIDATE_LICENSE_NUMBER = '/user/validLicenseNumber';

// Notifications Endpoints
export const ALL_NOTIFICATIONS = '/notifications';
export const UPDATE_NOTIFICATIONS_READ_STATUS = '/notifications/read';
