import DOMPurify from 'dompurify';
import React from 'react';

const sanitize = (dirty) => ({
  __html: DOMPurify.sanitize(dirty),
});
/**
 * Sanitizes using DOMPurify html and outputs wrapped in a div.
 * @param props with html to be sanitized
 * @returns
 */
const HTMLOutput = ({ html }) => {
  return <div dangerouslySetInnerHTML={sanitize(html)} />;
};
export default HTMLOutput;
