// External Dependencies
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Typography,
  Grid,
  CardActionArea,
  Box,
  useTheme,
  Stack,
} from '@mui/material/';
import { FiClock } from 'react-icons/fi';

type ActiveEngagementCardProps = {
  engagementId: number;
  title: string;
  payout: number;
  duration: number;
  state: string;
};

const ActiveEngagementCard = ({
  engagementId,
  title,
  payout,
  duration,
  state,
}: ActiveEngagementCardProps) => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const theme = useTheme();
  // 1. return Completed Engagement Row component
  return (
    <Grid item xs={12} sm={12} md={6} lg={4}>
      <CardActionArea
        sx={{
          minHeight: '110px',
          borderRadius: 2,
          backgroundColor: theme.palette.keyops.white.main,
          px: 2,
          py: 1.5,
          boxShadow: `0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.10);`,
        }}
        onClick={() => navigate(`/engagement/${engagementId}`)}
      >
        {/* Engagement title */}
        <Box minHeight="48px">
          <Typography
            variant="body1"
            color={theme.palette.keyops.black.main}
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: 2,
              WebkitBoxOrient: 'vertical',
            }}
          >
            {title}
          </Typography>
        </Box>
        {/* Amount earned, Survey and Calendar Icons with Text */}
        <Stack direction={'row'} gap={1} mt={1}>
          {/* Amount Earned */}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              px: 1.5,
              height: { xs: '32px', md: '32px' },
              borderRadius: 2,
              backgroundColor: theme.palette.keyops.blue.light,
            }}
          >
            <Typography
              fontSize={{ sm: '12px', md: '14px' }}
              fontWeight={500}
              color={theme.palette.keyops.blue.main}
            >
              ${payout}
            </Typography>
          </Box>
          {state === 'in_progress' && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                px: 1.5,
                height: { xs: '32px', md: '32px' },
                borderRadius: 2,
                backgroundColor: theme.palette.keyops.teal,
              }}
            >
              <Typography
                fontSize={{ xs: '12px', sm: '12px', md: '14px' }}
                fontWeight={500}
                noWrap={true}
                color={theme.palette.keyops.white.main}
              >
                {t('general.invitation.state.in_progress')}
              </Typography>
            </Box>
          )}
          {state !== 'in_progress' && (
            <Box display="inline-flex" alignItems="center">
              <FiClock color={theme.palette.keyops.blue.dark} />
              <Typography
                variant="body2"
                color={theme.palette.keyops.black.main}
                ml={1}
              >{`${duration ? duration : ''}${
                duration
                  ? t('dashboard.activeEngagementsSection.minutes')
                  : t('dashboard.activeEngagementsSection.defaultDuration')
              }`}</Typography>
            </Box>
          )}
        </Stack>
      </CardActionArea>
    </Grid>
  );
};

export default ActiveEngagementCard;
