// External Dependencies
import { InputAdornment, Input } from '@mui/material';
import ButtonUnstyled from '@mui/base/ButtonUnstyled';
import { inputUnstyledClasses } from '@mui/base/InputUnstyled';
import { styled } from '@mui/system';
import { mdBreakPoint } from '../../utils/constants';

// Internal Dependencies

export const blue = {
  100: '#DAECFF',
  200: '#80BFFF',
  400: '#3399FF',
  600: '#0072E5',
};

export const grey = {
  50: '#F3F6F9',
  100: '#E7EBF0',
  200: '#E0E3E7',
  300: '#CDD2D7',
  400: '#B2BAC2',
  500: '#A0AAB4',
  600: '#6F7E8C',
  700: '#3E5060',
  800: '#2D3843',
  900: '#1A2027',
};

export const StyledInputRoot = styled('div')(
  ({ theme }) => `
  font-family: IBM Plex Sans, sans-serif;
  display: flex;
  font-weight: 500;
  margin: 10px 0px 10px 0px;
  border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[300]};
  border-radius: 30px;
  background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
  align-items: center;
  justify-content: center;

  &.${inputUnstyledClasses.focused} {
    outline: 3px solid ${theme.palette.mode === 'dark' ? blue[600] : blue[100]};
  }

  &:hover {
    background: ${theme.palette.mode === 'dark' ? '' : '#fff'};
    border-color: ${theme.palette.mode === 'dark' ? grey[700] : '#000'};
  }
`
);

export const StyledInputElement = styled(Input)(
  ({ theme }) => `
  @media (min-width: ${mdBreakPoint}) {
    font-size: 0.875rem;
  }
  font-family: inherit;
  font-weight: 400;
  line-height: 1.5;
  flex-grow: 1;
  color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  background: inherit;
  border: none;
  border-radius: inherit;
  padding: 8px 14px;
  outline: 0;
`,
  { shouldForwardProp: (prop) => prop !== 'disableUnderline' }
);

export const IconButton = styled(ButtonUnstyled)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: inherit;
  cursor: pointer;
`;

export const StyledInputAdornment = styled(InputAdornment)`
  margin: 8px;
  display: inline-flex;
`;
