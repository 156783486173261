import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useSurveyContext } from '../../../higher-order-components/AppLayout/SurveyContext';
import { Container, useMediaQuery, useTheme } from '@mui/material';
import ToastContext from '../../../components/Toast/ToastContext';
import { TOAST_TYPES } from '../../../utils/constants';
import analytics from '../../../utils/analytics';
import { CtaButton } from '../../../components/CtaButton';
import { EngagementDto } from '@keyops-hcp/dtos';

export const SurveyIframe = ({
  ssLink,
  engagement,
}: {
  ssLink: string;
  engagement: EngagementDto;
}) => {
  const { t } = useTranslation();

  const { setSurveyIframeRendered, saveAndResumeActive } = useSurveyContext();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();

  const { triggerToast } = useContext(ToastContext);

  useEffect(() => {
    return () => {
      if (saveAndResumeActive) {
        triggerToast({
          type: TOAST_TYPES.SUCCESS,
          duration: 6000, //ten seconds
          message: t('engagement.saveAndExitSuccessMessage'),
        });
      }
    };
  }, [saveAndResumeActive]);

  useEffect(() => {
    setSurveyIframeRendered(true);

    return () => {
      setSurveyIframeRendered(false);
    }; // cleanup function to reset the state when the component is unmounted
  });

  const finishLater = () => {
    analytics.track('Save And Exit Engagement', {
      title: engagement.title,
      id: engagement.id,
    });

    navigate('/');
  };
  const iframe = (
    <iframe
      title={'survey'}
      style={{ border: 'none' }}
      src={ssLink}
      width="100%"
      height="100%"
    ></iframe>
  );

  if (isMobile) {
    return (
      <div
        style={{
          width: '100vw',
          height: '100vh',
        }}
      >
        {iframe}
      </div>
    );
  }
  return (
    <Container
      sx={{
        height: '80vh',
      }}
    >
      {iframe}
      {saveAndResumeActive && (
        <Container sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <CtaButton
            action={() => {
              finishLater();
            }}
            label={t('engagement.saveAndExit')}
          />
        </Container>
      )}
    </Container>
  );
};
