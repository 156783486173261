// External Dependencies
import React, { useState, useEffect, useContext } from 'react';
import {
  Typography,
  Box,
  Button,
  CircularProgress,
  Stack,
} from '@mui/material/';
import { FiShare, FiLink, FiMail } from 'react-icons/fi';
import { styled } from '@mui/system';

// Internal Dependencies
import { axiosCall } from '../../utils/api';
import ToastContext from '../Toast/ToastContext';
import { useTranslation } from 'react-i18next';

const DesktopButtons = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-evenly',
  alignItems: 'center',
  width: '225px',
  margin: '0 auto 0 auto',
  [theme.breakpoints.down('sm')]: {
    display: navigator.share ? 'none' : 'flex',
    marginBottom: '20px',
  },
}));

const ShareButton = styled(Button)(({ theme }) => ({
  fontSize: '18px',
  textTransform: 'none',
  borderRadius: '6px',
  display: navigator.share ? 'flex' : 'none',
  margin: '8px auto 0 auto',
  [theme.breakpoints.up('sm')]: {
    display: 'none',
  },
}));

const ModalShareIcon = styled(Button)(({ theme }) => ({
  display: 'flex',
  width: '50px',
  minWidth: '50px',
  height: '50px',
  minHeight: '50px',
  borderRadius: '25px',
  background: theme.palette.keyops.blue.light,
  '& svg': {
    width: '25px',
    height: '25px',
  },
}));

const PostEngagementShareIcon = styled(Button)(({ theme }) => ({
  display: 'flex',
  width: '56px',
  minWidth: '56px',
  height: '56px',
  minHeight: '56px',
  borderRadius: '28px',
  background: theme.palette.keyops.white.main,
  '& svg': {
    width: '30px',
    height: '30px',
  },
}));

const ShareEngagement = ({
  engagementId,
  closeReferral,
  postEngagement,
}: {
  engagementId: number | boolean;
  closeReferral: (engagementId: number | boolean) => void;
  postEngagement?: boolean;
}) => {
  const { t } = useTranslation();

  const [link, setLink] = useState('');
  const [loadingData, setLoadingData] = useState(true);
  const [linkCopied, setLinkCopied] = useState(false);
  const [shareReferralBody, setShareReferralBody] = useState('');
  const [shareMessageBody, setShareMessageBody] = useState('');
  const [emailSubject, setEmailSubject] = useState('');

  const mobileShare = () => {
    navigator.share({
      title: t('share.title'),
      text: shareMessageBody,
      url: link,
    });
  };

  const handleCopyClick = async () => {
    navigator.clipboard.writeText(link);
  };

  const mailToClick = async () => {
    const mailtoLink = `mailto:?subject=${encodeURIComponent(
      emailSubject
    )}&body=${encodeURIComponent(`${shareMessageBody}\n\n${link}`)}`;

    window.location.href = mailtoLink;
  };
  const { triggerToast } = useContext(ToastContext);

  const ShareIcon = postEngagement ? PostEngagementShareIcon : ModalShareIcon;

  useEffect(() => {
    const getEngagement = async () => {
      let results;
      try {
        results = await axiosCall(false, 'get', 'referral-link', false, {
          engagementId: engagementId,
        });

        if (results.status >= 200) {
          const { link, referralPayout, engagementPayout, estimatedTime } =
            results.data.data;
          setLink(link);
          setLoadingData(false);
          setShareReferralBody(
            referralPayout
              ? t('share.body', { referralPayout })
              : t('share.bodyNoPayout')
          );
          setEmailSubject(
            engagementPayout
              ? t('share.emailSubject', {
                  engagementPayout,
                  estimatedTime,
                })
              : t('share.emailSubjectNoPayout')
          );
          setShareMessageBody(
            engagementPayout
              ? t('share.shareBody', {
                  engagementPayout,
                  estimatedTime,
                })
              : t('share.shareBodyNoPayout')
          );
        }
      } catch (error) {
        closeReferral(engagementId);
        setLoadingData(false);
        triggerToast({
          type: 'error',
          message: results?.response?.data?.message,
        });
        console.error(error);
      }
    };

    getEngagement();
  }, []);

  // By default engagementId will be false, prevents misfiring
  if (loadingData || engagementId === false) {
    return (
      <Box
        sx={{ minHeight: { xs: '220px', md: '200px' } }}
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
      >
        <CircularProgress aria-label="progress" size="5em" />
      </Box>
    );
  }

  return (
    <>
      <Typography
        aria-label="share"
        fontSize={postEngagement ? '16px' : '18px'}
        variant="h6"
        padding={'10px'}
        component="h2"
        fontWeight={'600'}
        textTransform={'capitalize'}
        color={'keyops.gray.700'}
      >
        {t('share.title')}
      </Typography>
      <Typography
        sx={{
          padding: '0px 10px 10px 10px',
        }}
        fontSize="16px"
        color={'keyops.gray.700'}
      >
        {shareReferralBody}
      </Typography>
      <ShareButton
        onClick={() => {
          mobileShare();
        }}
        variant="contained"
        startIcon={<FiShare />}
      >
        {t('share.share')}
      </ShareButton>
      <DesktopButtons>
        <Stack
          direction={'column'}
          alignItems={'center'}
          padding={'8px'}
          onClick={() => {
            setLinkCopied(true);
            handleCopyClick();
          }}
        >
          <ShareIcon>
            <FiLink color="primary" />
          </ShareIcon>
          <Typography
            fontSize="14px"
            paddingTop={'4px'}
            color={'keyops.gray.700'}
          >
            {linkCopied ? t('share.copiedLink') : t('share.copyLink')}
          </Typography>
        </Stack>
        <Stack
          direction={'column'}
          alignItems={'center'}
          padding={'8px'}
          onClick={() => {
            mailToClick();
          }}
        >
          <ShareIcon>
            <FiMail color="primary" />
          </ShareIcon>
          <Typography
            paddingTop={'4px'}
            fontSize="14px"
            color={'keyops.gray.700'}
          >
            {t('share.email')}
          </Typography>
        </Stack>
      </DesktopButtons>
    </>
  );
};

export default ShareEngagement;
