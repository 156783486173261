import React, { useEffect } from 'react';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import { ThemeProvider } from '@mui/material/styles';
import { Outlet } from 'react-router-dom';

import './languages/i18n.config';
import { UserContextProvider } from './UserContext';
import analytics from './utils/analytics';

// HOC
import { ContactUIProvider } from './higher-order-components/ContactUIContext';
import ContactComponent from './higher-order-components/ContactUIContext/ContactComponent';
import { SurveyContextProvider } from './higher-order-components/AppLayout/SurveyContext';
import { AuthProvider } from './higher-order-components/useAuth';

// components
import { ToastProvider } from './components/Toast/ToastProvider';
import Toast from './components/Toast';

import { theme } from './theme';
import usePageViewsAnalytics from './custom-hooks/usePageViewsAnalytics';

const App = () => {
  useEffect(() => {
    analytics.init();
  }, []);
  usePageViewsAnalytics();

  return (
    <ThemeProvider theme={theme}>
      {/* Languages provider */}
      <I18nextProvider i18n={i18next}>
        <ToastProvider>
          <AuthProvider>
            <UserContextProvider>
              <ContactUIProvider>
                <ContactComponent />
                <SurveyContextProvider>
                  <Toast />
                  <Outlet />
                </SurveyContextProvider>
              </ContactUIProvider>
            </UserContextProvider>
          </AuthProvider>
        </ToastProvider>
      </I18nextProvider>
    </ThemeProvider>
  );
};

export default App;
