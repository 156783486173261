import React, { MouseEvent } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';

import NotificationsPopover from './NotificationsPopover';
import NotificationsDrawer from './NotificationsDrawer';
import { DisplayNotification } from '../../../../utils/functions/notifications-utils';

interface NotificationsUIProps {
  notificationAnchor: HTMLButtonElement | null;
  isOpen: boolean;
  handleClose: () => void;
  toggleNotificationsUI: (event: MouseEvent<HTMLButtonElement>) => void;
  loadingNotifications: boolean;
  notificationsError: string | null;
  notificationsList: DisplayNotification[];
}

const NotificationsUI = ({
  notificationAnchor,
  isOpen,
  handleClose,
  toggleNotificationsUI,
  loadingNotifications,
  notificationsError,
  notificationsList,
}: NotificationsUIProps) => {
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down('sm'));

  const CommonNotificationsComponent = isMobile
    ? NotificationsDrawer
    : NotificationsPopover;

  return (
    <div data-testid="NotificationsComponent">
      <CommonNotificationsComponent
        notificationAnchor={notificationAnchor}
        isOpen={isOpen}
        toggleNotificationsUI={toggleNotificationsUI}
        handleClose={handleClose}
        notificationsList={notificationsList}
        loadingNotifications={loadingNotifications}
        notificationsError={notificationsError}
      />
    </div>
  );
};

export default NotificationsUI;
