import React, { useMemo } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { EarningEngagementsResponseDto } from '../../../dto/earnings.dto';
import ColumnHeader from '../../../components/TableComponents/ColumnHeader';
import { dateFormatter } from '../../../utils/functions/common-utils';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const columnHelper = createColumnHelper<EarningEngagementsResponseDto>();

const useEarningsTableColumns = () => {
  const { t } = useTranslation();

  const columns = useMemo(
    () => [
      columnHelper.accessor('title', {
        header: ({ column }) => (
          <ColumnHeader
            headerText={t('earnings.earningsTable.columnHeaders.name')}
            sorted={column.getIsSorted()}
          />
        ),
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor('type', {
        header: ({ column }) => (
          <ColumnHeader
            headerText={t('earnings.earningsTable.columnHeaders.type')}
            sorted={column.getIsSorted()}
          />
        ),
        cell: (info) => (
          <span style={{ textTransform: 'capitalize' }}>{info.getValue()}</span>
        ),
      }),
      columnHelper.accessor('completedAt', {
        header: ({ column }) => (
          <ColumnHeader
            headerText={t('earnings.earningsTable.columnHeaders.completed')}
            sorted={column.getIsSorted()}
          />
        ),
        cell: (info) => dateFormatter(info.getValue()),
      }),
      columnHelper.accessor('amount', {
        header: () => (
          <Typography pr={8} align="right" fontWeight={500}>
            {t('earnings.earningsTable.columnHeaders.earned')}
          </Typography>
        ),
        cell: (info) => (
          <span>${Intl.NumberFormat('currency').format(info.getValue())}</span>
        ),
      }),
    ],
    []
  );

  return columns;
};

export default useEarningsTableColumns;
