import * as React from 'react';
import { Box, Stack, Typography, useTheme } from '@mui/material';

import i18next from '../../../languages/i18n.config';
import Carousel1Image from '../images/carousel1.png';
import Carousel2Image from '../images/carousel2.png';
import Carousel3Image from '../images/carousel3.png';
import { MarketingImage } from '../styles';
import { hardwareAccelerationStyles } from '../../../styles/hardware-acceleration-styles';

const slides = [
  {
    label: i18next.t(`onboarding.marketing.carousel.slide1.title`),
    imgSrc: Carousel1Image,
    title: i18next.t(`onboarding.marketing.carousel.slide1.title`),
    subTitle: i18next.t(`onboarding.marketing.carousel.slide1.subTitle`),
  },
  {
    label: i18next.t(`onboarding.marketing.carousel.slide2.title`),
    imgSrc: Carousel2Image,
    title: i18next.t(`onboarding.marketing.carousel.slide2.title`),
    subTitle: i18next.t(`onboarding.marketing.carousel.slide2.subTitle`),
  },
  {
    label: i18next.t(`onboarding.marketing.carousel.slide3.title`),
    imgSrc: Carousel3Image,
    title: i18next.t(`onboarding.marketing.carousel.slide3.title`),
    subTitle: i18next.t(`onboarding.marketing.carousel.slide3.subTitle`),
  },
];

const interval = 4000;

const LandingPageCarousel = () => {
  const theme = useTheme();

  const [currentIndex, setCurrentIndex] = React.useState(0);

  React.useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
    }, interval);

    return () => {
      clearInterval(intervalId);
    };
  }, [currentIndex, interval, slides]);

  const handleStepChange = (step: number) => {
    setCurrentIndex(step);
  };

  return (
    <>
      <Box overflow={'hidden'}>
        <Box
          display={'flex'}
          sx={{
            ...hardwareAccelerationStyles,
            transition: 'transform 0.5s ease-in-out',
            transform: `translateX(-${currentIndex * 100}%)`,
          }}
        >
          {slides.map((slide, index) => (
            <Box
              key={index}
              flexShrink={0}
              width={'100%'}
              maxWidth={'100%'}
              textAlign={'center'}
            >
              <MarketingImage src={slide.imgSrc} alt={slide.label} />
              <Stack mt={2} spacing={2} textAlign={'center'}>
                <Typography
                  variant={'h6'}
                  component={'h1'}
                  fontWeight={600}
                  color={theme.palette.keyops.black.main}
                >
                  {slide.title}
                </Typography>
                <Typography
                  variant={'body1'}
                  component={'h2'}
                  color={theme.palette.keyops.black.main}
                  minHeight={72}
                >
                  {slide.subTitle}
                </Typography>
              </Stack>
            </Box>
          ))}
        </Box>
      </Box>
      <Stack direction="row" spacing={1} justifyContent={'center'}>
        {slides.map((_, index) => (
          <Box
            key={index}
            sx={{
              ...hardwareAccelerationStyles,
              height: 10,
              width: currentIndex === index ? 30 : 10,
              borderRadius: '50px',
              backgroundColor:
                currentIndex === index
                  ? theme.palette.keyops.blue.dark
                  : theme.palette.keyops.gray.main,
              transition: 'all 0.25s ease',
              ':hover': {
                cursor: 'pointer',
              },
            }}
            onClick={() => handleStepChange(index)}
          />
        ))}
      </Stack>
    </>
  );
};

export default LandingPageCarousel;
