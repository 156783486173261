import React from 'react';
import { Button } from '@mui/material';
import { styled } from '@mui/system';

export const StyledButton = styled(Button)`
  text-transform: none;
  backgroundcolor: theme.palette.keyops.blue.main90;
`;

const PrimaryButton = ({ children, ...props }) => {
  return (
    <StyledButton variant="contained" disableElevation {...props}>
      {children}
    </StyledButton>
  );
};

export default PrimaryButton;
