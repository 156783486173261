// External Dependencies
import React, { useMemo } from 'react';
import { Stack, useTheme } from '@mui/material';
import { useWatch } from 'react-hook-form';

// Internal Dependencies
import KeyOpsTextField from '../components/TextField';
import KeyOpsSingleSelect from '../components/SingleSelect';
import i18next from '../../../languages/i18n.config';
import { FormTitle } from '../styles';
import { FORM_FIELDS } from './form-constants';
import {
  CountryOptions,
  StateOptions,
  ProvinceOptions,
} from '../../../utils/enum-display';
import { KeyopsSubTitle1 } from '../../../components/Headers';

const formFields = FORM_FIELDS.accountDetails;

export const getStateProvValues = (selectedCountry) => {
  switch (selectedCountry) {
    case 'US':
      return {
        label: i18next.t('onboarding.forms.accountDetails.formFields.state'),
        rules: i18next.t('onboarding.forms.fieldErrors.required', {
          field: 'State',
        }),
        options: StateOptions,
      };
    case 'CA':
      return {
        label: i18next.t('onboarding.forms.accountDetails.formFields.province'),
        rules: i18next.t('onboarding.forms.fieldErrors.required', {
          field: 'Province',
        }),
        options: ProvinceOptions,
      };
    default:
      return {
        label: i18next.t(
          'onboarding.forms.accountDetails.formFields.stateOrProvince'
        ),
        rules: i18next.t('onboarding.forms.fieldErrors.required', {
          field: i18next.t(
            'onboarding.forms.accountDetails.formFields.stateOrProvince'
          ),
        }),
        options: [],
      };
  }
};

export const AccountDetails = ({ control, register, errors }) => {
  const { palette } = useTheme();

  const selectedCountry = useWatch({
    control,
    name: 'country',
  });

  const stateProvValues = useMemo(
    () => getStateProvValues(selectedCountry),
    [selectedCountry]
  );

  return (
    <Stack spacing={4}>
      <FormTitle>
        {i18next.t('onboarding.forms.accountDetails.title')}
      </FormTitle>
      <KeyOpsTextField
        fieldName={formFields.firstName.name}
        placeholder={formFields.firstName.label}
        inputProps={{ 'data-testid': formFields.firstName.name }}
        rules={{
          required: i18next.t(`onboarding.forms.fieldErrors.required`, {
            field: formFields.firstName.label,
          }),
        }}
        register={register}
        errors={errors}
      />
      <KeyOpsTextField
        fieldName={formFields.lastName.name}
        placeholder={formFields.lastName.label}
        inputProps={{ 'data-testid': formFields.lastName.name }}
        rules={{
          required: i18next.t(`onboarding.forms.fieldErrors.required`, {
            field: formFields.lastName.label,
          }),
        }}
        register={register}
        errors={errors}
      />
      <KeyopsSubTitle1 fontSize={'0.875rem'} color={palette.keyops.gray[500]}>
        {i18next.t(
          'onboarding.forms.accountDetails.selectCountryStateProvCity'
        )}
      </KeyopsSubTitle1>
      <KeyOpsSingleSelect
        fieldName={formFields.country.name}
        placeholder={formFields.country.label}
        inputProps={{ 'data-testid': formFields.country.name }}
        defaultValue={''}
        rules={{
          required: i18next.t(`onboarding.forms.fieldErrors.required`, {
            field: formFields.country.label,
          }),
        }}
        options={CountryOptions}
        control={control}
        errors={errors}
      />
      <KeyOpsSingleSelect
        fieldName={formFields.provinceOfPractice.name}
        placeholder={stateProvValues.label}
        inputProps={{ 'data-testid': formFields.provinceOfPractice.name }}
        control={control}
        defaultValue={''}
        rules={{
          required: stateProvValues.rules,
        }}
        options={stateProvValues.options}
        errors={errors}
      />
      <KeyOpsTextField
        fieldName={formFields.cityOfPractice.name}
        placeholder={formFields.cityOfPractice.label}
        inputProps={{ 'data-testid': formFields.cityOfPractice.name }}
        rules={{
          required: i18next.t(`onboarding.forms.fieldErrors.required`, {
            field: formFields.cityOfPractice.label,
          }),
        }}
        register={register}
        errors={errors}
      />
    </Stack>
  );
};
