import { Button } from '@mui/material';
import React from 'react';
import { theme } from '../../theme';
export const CtaButton = ({
  label,
  action,
  disabled = false,
  endIcon = undefined,
}) => {
  return (
    <Button
      disabled={disabled}
      aria-label={label}
      variant="contained"
      endIcon={endIcon}
      sx={{
        borderRadius: 2,
        fontSize: 18,
        fontWeight: 500,
        textTransform: 'none',
        backgroundColor: theme.palette.keyops.blue[500],
        paddingY: 1,
        paddingX: 2.5,
        display: 'inline-flex',
        width: 'auto',
      }}
      disableElevation
      onClick={action}
    >
      {label}
    </Button>
  );
};
