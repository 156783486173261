import { Box, Typography, useTheme } from '@mui/material';
import { ArrowForward } from '@mui/icons-material';
import { InvitationState } from '@keyops-hcp/constants';

import React from 'react';
import { useTranslation } from 'react-i18next';

import PreEngagePhysician from '../../../images/Pre-Engage-Physician.png';
import { BackToDashboardButton } from '../../../components/BackToDashboardButton';
import { CtaButton } from '../../../components/CtaButton';
import HTMLOutput from '../../../components/HTMLOutput';
import { EngagementEligibilityConfirmation } from './EngagementEligibilityConfirmation';

export const PreEngagementPage = ({
  title,
  payoutValue,
  summary,
  estimatedTime,
  state,
  hasOtherEligibilityCriteria,
  otherEligibilityCriteria,
  startEngagement,
  declineEngagement,
}: {
  title: string;
  payoutValue: number;
  summary: string;
  estimatedTime: number;
  state: InvitationState;
  hasOtherEligibilityCriteria: boolean;
  otherEligibilityCriteria: string;
  startEngagement: () => void;
  declineEngagement: () => void;
}) => {
  const { t } = useTranslation();

  const getButtonLabel = () => {
    if (state === 'in_progress') {
      return t('engagement.resumeButtonLabel');
    }
    return t('engagement.startButtonLabel');
  };

  const theme = useTheme();

  return (
    <Box flexGrow={1} overflow={'auto'} px={{ xs: 2, sm: 6, md: 8 }} py={3}>
      <EngagementEligibilityConfirmation
        startOpen={hasOtherEligibilityCriteria && state === 'new'}
        otherEligibilityCriteria={otherEligibilityCriteria}
        declineEngagement={declineEngagement}
      />
      <BackToDashboardButton />

      {/** Image */}
      <Box
        display={{ xs: 'none', sm: 'flex' }}
        justifyContent="center"
        alignItems="center"
        my={2}
      >
        <img
          src={PreEngagePhysician}
          alt={t('engagement.preEngagePhysicianAltText')}
          style={{ width: '18%', border: 'none', verticalAlign: 'bottom' }}
        />
      </Box>

      <Box
        width={{ base: '100%', md: '70%' }}
        margin="auto"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        paddingX={{ xs: 3, md: 0 }}
      >
        {/** Title */}
        <Typography
          fontWeight={600}
          fontSize={20}
          lineHeight={'24px'}
          align="center"
          color={theme.palette.keyops.black.main}
        >
          {title}
        </Typography>
        {/** Earnings */}
        <Box padding={1.5} display="flex" justifyContent="center">
          <Box
            display="inline-flex"
            borderRadius={2}
            justifyContent="center"
            paddingY={1}
            paddingX={2}
            sx={{ backgroundColor: theme.palette.keyops.blue.light }}
          >
            <Typography
              fontSize={18}
              fontWeight={500}
              color={theme.palette.keyops.blue.main}
            >
              {t('engagement.earnings', { value: payoutValue })}
            </Typography>
          </Box>
        </Box>
        {/** Content */}
        {summary && (
          <Typography>
            <HTMLOutput html={summary} />
          </Typography>
        )}
        {/** Estimated time */}
        <Typography paddingTop={1} paddingBottom={2}>
          {estimatedTime
            ? t('engagement.estimatedTime', { value: estimatedTime })
            : t('engagement.estimatedTimeDefault')}
        </Typography>
        {/** Start button */}
        <Box display="flex" justifyContent="left">
          <CtaButton
            endIcon={<ArrowForward />}
            action={() => {
              startEngagement();
            }}
            label={getButtonLabel()}
          />
        </Box>
      </Box>
    </Box>
  );
};
