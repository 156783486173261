// External Dependencies
import React, { useMemo } from 'react';
import { Alert, Stack } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

// Internal Dependencies
import KeyOpsToggleButtonGroup from '../components/ToggleButtonGroup';
import { FormTitle } from '../styles';
import { FORM_FIELDS } from './form-constants';
import PhysicianPracticeDetails from './PhysicianPracticeDetails';
import PharmacistPracticeDetails from './PharmacistPracticeDetails';
import NursePracticeDetails from './NursePracticeDetails';
import { HcpTypeOptions } from '../../../utils/enum-display';
import { formatSpecialties } from '../../../utils/functions/eligibility.utils';
import { theme } from '../../../theme';

const formFields = FORM_FIELDS.practiceDetails;

export const PracticeDetails = ({
  control,
  register,
  unregister,
  errors,
  setValue,
  attachedEngagement,
}) => {
  const hcpTypeValue = useWatch({
    control,
    name: 'hcpType',
  });

  const { t } = useTranslation();
  const allowedSpecialties = useMemo(() => {
    if (
      !attachedEngagement ||
      !attachedEngagement.allowedSpecialties ||
      attachedEngagement.hcpType !== 'physician'
    )
      return [];

    return formatSpecialties(attachedEngagement.allowedSpecialties);
  }, []);

  // Eligibility message depends on required hcpTypes and specialties for this engagement
  const eligibilityMessage = useMemo(() => {
    if (!attachedEngagement) return undefined;

    const allowedHcpType = HcpTypeOptions.getDisplay(
      attachedEngagement.hcpType
    );

    const returnedText =
      allowedSpecialties.length > 0 && allowedHcpType
        ? t(`onboarding.forms.eligibilityMessageWithSpecialties`, {
            requiredHCPType: allowedHcpType,
            requiredSpecialties: allowedSpecialties,
          })
        : t(`onboarding.forms.eligibilityMessageNoSpecialties`, {
            requiredHCPType: allowedHcpType,
          });

    return returnedText;
  }, [allowedSpecialties, hcpTypeValue]);

  return (
    <Stack spacing={4}>
      <FormTitle>{t('onboarding.forms.practiceDetails.title')}</FormTitle>
      <KeyOpsToggleButtonGroup
        fieldName={formFields.hcpType.name}
        defaultValue={''}
        rules={{
          required: t(`onboarding.forms.fieldErrors.required`, {
            field: formFields.hcpType.label,
          }),
        }}
        options={HcpTypeOptions}
        control={control}
        setValue={setValue}
        errors={errors}
      />
      {/* Display eligibility message only if the selected hcpType is different from the required one */}
      {eligibilityMessage &&
        hcpTypeValue &&
        hcpTypeValue !== attachedEngagement?.hcpType && (
          <Alert
            severity="info"
            icon={
              <InfoOutlinedIcon
                sx={{
                  mr: '-4px',
                  p: '2px 4px',
                }}
                htmlColor={theme.palette.keyops.blue.dark}
              />
            }
            style={{
              marginTop: 24,
              padding: '12px 8px 12px 8px',
              width: 'auto',
              textAlign: 'left',
              alignItems: 'top',
              lineHeight: '24px',
              fontSize: '16px',
              maxWidth: 650,
            }}
          >
            {eligibilityMessage}
          </Alert>
        )}
      {hcpTypeValue === 'physician' && (
        <PhysicianPracticeDetails
          control={control}
          register={register}
          errors={errors}
          setValue={setValue}
          attachedEngagement={attachedEngagement}
        />
      )}
      {hcpTypeValue === 'pharmacist' && (
        <PharmacistPracticeDetails
          control={control}
          register={register}
          unregister={unregister}
          errors={errors}
        />
      )}
      {hcpTypeValue === 'nurse' && (
        <NursePracticeDetails
          control={control}
          register={register}
          unregister={unregister}
          setValue={setValue}
          errors={errors}
        />
      )}
    </Stack>
  );
};
