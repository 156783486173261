// Breakpoints
export const mobileBreakPoint = '900px';
export const xsBreakPoint = '0px';
export const smBreakPoint = '600px';
export const mdBreakPoint = '900px';
export const lgBreakPoint = '1200px';
export const xlBreakPoint = '1536px';

// local storage constants
export const keyOpsLSToken = 'Keyops__refreshToken';

// Common constants
export const DEFAULT_STRING_LENGTH = 50;
export const TOAST_TYPES = {
  SUCCESS: 'success',
  ERROR: 'error',
  WARNING: 'warning',
  INFO: 'info',
};

export const TERMS_OF_USE_LINK = 'https://www.keyops.io/terms-of-use';
export const PRIVACY_POLICY_LINK = 'https://www.keyops.io/privacy-policy';

//---------- Used internal email addresses -----------//
export const SURVEY_EMAIL_ADDRESS = 'survey@keyops.io';
