import mixpanel from 'mixpanel-browser';

export default {
  init: async () => {
    const token = process.env.REACT_APP_MIXPANEL_TOKEN;
    if (token) {
      try {
        mixpanel.init(token, { debug: false });
      } catch (e) {
        console.log('Mix Panel Error Handler', e);
      }
    } else {
      console.log('token not found, no analytics');
    }
  },
  track: async (eventName, properties) => {
    try {
      mixpanel.track(eventName, properties);
    } catch (e) {
      console.log('Mix Panel Error Handler', e);
    }
  },
  identify: async (id, properties) => {
    try {
      mixpanel.identify(id);
      if (properties) {
        const fieldsToSend = [
          'firstName',
          'lastName',
          'email',
          'workEmail',
          'created_at',
          'updated_at',
          'specialty',
          'gender',
          'yearOfBirth',
          'provinceOfPractice',
          'practiceLicenseYear',
          'practiceSetting',
          'procedureSetting',
          'subSpecialty',
          'languagePreference',
          'workEmailVerified',
          'engagementPreference',
        ];
        const analyticsProperties = Object.assign(
          {},
          ...fieldsToSend.map((key) => ({ [key]: properties[key] }))
        );
        analyticsProperties['$name'] =
          analyticsProperties.firstName + ' ' + analyticsProperties.lastName;
        analyticsProperties['$email'] = analyticsProperties.email;
        mixpanel.people.set(analyticsProperties);
      }
    } catch (e) {
      console.log('Mix Panel Error Handler', e);
    }
  },
};
