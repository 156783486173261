// External Dependencies
import React from 'react';
import { CardActionArea, Stack, Typography, useTheme } from '@mui/material/';

import { RewardTierNames } from '../../../data';

interface RewardCardProps {
  tier: string;
  rewardName: string;
  rewardDescription: string;
  selected: boolean;
  setSelectedCard: (claimed: string) => void;
  imageUrl: string;
}

const RewardCard = ({
  tier,
  rewardName,
  rewardDescription,
  selected,
  setSelectedCard,
  imageUrl,
}: RewardCardProps): JSX.Element => {
  const theme = useTheme();
  return (
    <CardActionArea
      data-testid={`RewardCard`}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        width: { xs: '266px', sm: '290px' },
        p: '8px',
        borderRadius: '8px',
        border: selected
          ? `1px solid ${theme.palette.keyops.blue.dark}`
          : '1px solid transparent',
        boxShadow:
          '0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.10)',
      }}
      onClick={() => {
        setSelectedCard(rewardName);
      }}
    >
      <Stack
        direction={'column'}
        display={tier === RewardTierNames.silver ? 'block' : 'flex'}
        alignItems={'center'}
      >
        <img height={'48px'} src={`${imageUrl}`} alt={rewardName} />
        {tier === RewardTierNames.silver && (
          <Typography>{rewardDescription}</Typography>
        )}
      </Stack>
    </CardActionArea>
  );
};
export default RewardCard;
