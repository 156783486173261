import React from 'react';
import {
  Typography,
  useTheme,
  Stack,
  Link,
  useMediaQuery,
} from '@mui/material';
import { HcpType } from '@keyops-hcp/constants';
import { useTranslation } from 'react-i18next';

import { EARNINGS } from '../../../utils/routes';

interface EarningsButtonProps {
  hcpType: HcpType;
  totalEarnings: number;
}

const EarningsButton: React.FC<EarningsButtonProps> = ({
  hcpType,
  totalEarnings,
}: EarningsButtonProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { t } = useTranslation();

  return (
    <Stack
      direction={{
        xs: hcpType !== 'physician' ? 'row-reverse' : 'column',
        sm: 'column',
      }}
      justifyContent={{ xs: 'space-between', sm: 'flex-start' }}
      alignItems={{
        xs: hcpType !== 'physician' ? 'center' : 'flex-start',
        sm: 'flex-end',
      }}
      width={hcpType !== 'physician' ? '100%' : undefined}
    >
      <Link href={EARNINGS} data-testid="earnings-link">
        <Typography
          display={'inline-flex'}
          sx={{
            ':hover': {
              textDecoration: { xs: 'none', sm: 'underline' },
            },
            color: theme.palette.keyops.blue.dark,
            fontWeight: 600,
            fontSize: '24px',
          }}
        >
          ${Intl.NumberFormat('currency').format(totalEarnings)}
        </Typography>
      </Link>
      <Typography
        variant={isMobile && hcpType !== 'physician' ? 'body1' : 'body2'}
        sx={{
          color: theme.palette.keyops.gray[600],
          fontWeight: 500,
        }}
        display={'inline-flex'}
      >
        {t('dashboard.earnings.earningsButton')}
      </Typography>
    </Stack>
  );
};

export default EarningsButton;
