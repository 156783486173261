import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import analytics from '../utils/analytics';

const usePageViewsAnalytics = () => {
  const location = useLocation();
  useEffect(() => {
    analytics.track('Route Change', { path: location.pathname });
  }, [location]);
};

export default usePageViewsAnalytics;
