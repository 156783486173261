import React, { MouseEvent, useCallback, useEffect, useState } from 'react';
import { AppBar, useMediaQuery, useTheme } from '@mui/material';

import MobileAppBar from './MobileAppBar';
import DesktopAppBar from './DesktopAppBar';
import NotificationsUI from './notifications/NotificationsUI';
import useNotifications from '../../../custom-hooks/useNotifications';
import { updateReadStatusCall } from '../../../api/notifications';

const CustomAppBar = ({
  toggleMobileSideNavDrawer,
}: {
  toggleMobileSideNavDrawer: (newOpen: boolean) => void;
}) => {
  const { breakpoints, palette } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down('sm'));

  // notification popover config
  const [notificationAnchor, setNotificationAnchor] =
    useState<HTMLButtonElement | null>(null);
  const toggleNotificationsUI = (event: MouseEvent<HTMLButtonElement>) => {
    setNotificationAnchor(event.currentTarget);
  };
  const handleNotificationsUIClose = () => {
    updateNotificationsReadStatus();
    setNotificationAnchor(null);
  };
  const isNotificationUIOpen = Boolean(notificationAnchor);

  const {
    list: notificationsList,
    setList: setNotificationsList,
    unread: areNotificationsUnread,
    setUnread: setNotificationsUnread,
    loading: loadingNotifications,
    error: notificationsError,
  } = useNotifications();

  useEffect(() => {
    setNotificationsUnread(
      notificationsList.some((notification) => !notification.read)
    );
  }, [notificationsList]);

  const updateNotificationsReadStatus = useCallback(async () => {
    try {
      const unreadNotifications = notificationsList
        .filter((notification) => !notification.read)
        .map((notification) => notification.id);

      if (!unreadNotifications.length) return;

      await updateReadStatusCall(unreadNotifications);
      setNotificationsUnread(false);
      setNotificationsList(
        notificationsList.map((item) =>
          item.read ? item : { ...item, read: true }
        )
      );
    } catch (e) {
      console.error('error updateNotificationsReadStatus - ', e);
    }
  }, [notificationsList]);

  return (
    <AppBar
      position={'fixed'}
      component="nav"
      sx={{
        bgcolor: !isMobile
          ? palette.keyops.background
          : palette.keyops.white.main,
        boxShadow: isMobile ? '0px 1px 2px 0px rgba(0, 0, 0, 0.06)' : 'none',
      }}
      data-testid="custom-app-bar"
    >
      {!isMobile && (
        <DesktopAppBar
          toggleNotificationsUI={toggleNotificationsUI}
          areNotificationsUnread={areNotificationsUnread}
        />
      )}
      {isMobile && (
        <MobileAppBar
          toggleNotificationsUI={toggleNotificationsUI}
          toggleMobileSideNavDrawer={toggleMobileSideNavDrawer}
          areNotificationsUnread={areNotificationsUnread}
        />
      )}
      <NotificationsUI
        notificationAnchor={notificationAnchor}
        isOpen={isNotificationUIOpen}
        handleClose={handleNotificationsUIClose}
        toggleNotificationsUI={toggleNotificationsUI}
        notificationsList={notificationsList}
        loadingNotifications={loadingNotifications}
        notificationsError={notificationsError}
      />
    </AppBar>
  );
};

export default CustomAppBar;
