// External Dependencies
import React from 'react';
import {
  Typography,
  Grid,
  CardActionArea,
  Box,
  useTheme,
} from '@mui/material/';
import { useTranslation } from 'react-i18next';

type ReferralOpportunitiesCardProps = {
  engagementId: number;
  title: string;
  payout: number;
  openReferral: (number) => void;
};

const ReferralOpportunitiesCard = ({
  engagementId,
  title,
  payout,
  openReferral,
}: ReferralOpportunitiesCardProps) => {
  const theme = useTheme();
  const { t } = useTranslation();

  // 1. return Completed Engagement Row component
  return (
    <Grid item xs={12} sm={12} md={6} lg={4}>
      <CardActionArea
        sx={{
          minHeight: '110px',
          borderRadius: 2,
          backgroundColor: theme.palette.keyops.white.main,
          px: 2,
          py: 1.5,
          boxShadow: `0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.10);`,
        }}
        onClick={() => openReferral(engagementId)}
      >
        {/* Engagement title */}
        <Box minHeight="48px">
          <Typography
            variant="body1"
            color={theme.palette.keyops.black.main}
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: 2,
              WebkitBoxOrient: 'vertical',
            }}
          >
            {title}
          </Typography>
        </Box>
        {/* Amount Earned */}
        <Box display="inline-flex" mt={1}>
          <Box
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            px={1}
            height={{ xs: '32px', md: '36px' }}
            borderRadius={2}
            bgcolor={payout ? theme.palette.keyops.blue.light : 'transparent'}
          >
            <Typography
              variant="caption"
              fontWeight={500}
              color={payout ? theme.palette.keyops.blue.main : 'transparent'}
            >
              {payout
                ? t(
                    'dashboard.referralOpportunitiesSection.referralBonusText',
                    {
                      payoutValue: payout,
                    }
                  )
                : null}
            </Typography>
          </Box>
        </Box>
      </CardActionArea>
    </Grid>
  );
};

export default ReferralOpportunitiesCard;
