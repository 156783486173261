import React from 'react';
import { Box, Button, useTheme } from '@mui/material';
import { createSearchParams, useSearchParams } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';

import { LOGIN } from '../../../utils/routes';
import EngagementInfoDisplay from './EngagementInfoDisplay';

const ReferralMobileMarketing = ({
  setMobileMarketingDisplayed,
  attachedEngagement,
}) => {
  const { t } = useTranslation();

  const theme = useTheme();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  return (
    <>
      <EngagementInfoDisplay engagement={attachedEngagement} />
      <Box
        width={'100%'}
        display={'flex'}
        flexDirection={'column'}
        gap={2}
        py={2}
      >
        <Button
          variant={'contained'}
          sx={{
            textTransform: 'inherit',
            width: '100%',
            '&:disabled': {
              backgroundColor: theme.palette.keyops.blue.main90,
              color: theme.palette.keyops.white.main,
            },
          }}
          disableElevation
          onClick={() => setMobileMarketingDisplayed(true)}
        >
          {t(`onboarding.forms.landingPage.createAccount`)}
        </Button>
        <Button
          variant={'outlined'}
          sx={{
            backgroundColor: theme.palette.keyops.white.main,
            textTransform: 'inherit',
            width: '100%',
            '&:disabled': {
              backgroundColor: theme.palette.keyops.blue.main90,
              color: theme.palette.keyops.white.main,
            },
          }}
          disableElevation
          onClick={() =>
            navigate({
              pathname: LOGIN,
              search: `?${createSearchParams(searchParams)}`,
            })
          }
        >
          {t(`onboarding.forms.landingPage.signIn`)}
        </Button>
      </Box>
    </>
  );
};

export default ReferralMobileMarketing;
