// External Dependencies
import React from 'react';
import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Control, Controller, get } from 'react-hook-form';

// Internal Dependencies
import { BooleanOptionsType, Options } from '../forms/form-constants';

type ValidationRuleType = {
  [key: string]: (value: string | null | undefined) => true | string;
};

type KeyOpsOptionSelectProps = {
  fieldName: string;
  placeholder: string;
  defaultValue?: string;
  rules?: { [key: string]: string } | ValidationRuleType; // validation rules
  options?: Options | BooleanOptionsType;
  control: Control;
  helperText?: string;
  setValue?: (name: string, value: unknown, config?: object) => void;
  errors: object;
};

const KeyOpsOptionSingleSelect = ({
  fieldName,
  placeholder,
  defaultValue = '',
  rules,
  options = [],
  control,
  helperText,
  setValue,
  errors,
}: KeyOpsOptionSelectProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const error = get(errors, fieldName);

  const renderInput = ({ field }) => (
    <Grid container spacing={1} mt={2}>
      {options.map((option, i) => (
        <Grid key={`${option.value}-${i}`} item xs={6} zeroMinWidth>
          <Button
            value={option.value}
            variant={field.value === option.value ? 'contained' : 'outlined'}
            onClick={() => setValue(fieldName, option.value)}
            size="small"
            sx={{
              height: 80,
              textTransform: 'capitalize',
              color:
                field.value === option.value
                  ? '#ffffff'
                  : theme.palette.keyops.gray[600],
              borderColor: theme.palette.keyops.gray[300],
              bgcolor:
                field.value === option.value
                  ? theme.palette.keyops.blue.dark
                  : !isMobile
                  ? 'transparent'
                  : theme.palette.keyops.white.main,
              ':hover': {
                borderColor: theme.palette.keyops.gray[300],
                bgcolor:
                  field.value === option.value
                    ? theme.palette.keyops.blue.dark90
                    : theme.palette.keyops.gray[50],
              },
            }}
            fullWidth
            disableElevation
          >
            {option.label}
          </Button>
        </Grid>
      ))}
    </Grid>
  );

  return (
    <FormControl error={!!error}>
      <Typography color={theme.palette.keyops.black.main}>
        {placeholder}
      </Typography>
      <Controller
        name={fieldName}
        control={control}
        defaultValue={defaultValue}
        rules={rules}
        render={renderInput}
      />
      {helperText && (
        <FormHelperText sx={{ ml: 0 }}>{helperText}</FormHelperText>
      )}
      {error && <FormHelperText sx={{ ml: 0 }}>{error.message}</FormHelperText>}
    </FormControl>
  );
};

export default KeyOpsOptionSingleSelect;
