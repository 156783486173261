import React, { MouseEvent } from 'react';
import { Badge, IconButton, useTheme } from '@mui/material';
import { FiBell } from 'react-icons/fi';

const NotificationsButton = ({
  toggleNotificationsUI,
  areNotificationsUnread,
}: {
  toggleNotificationsUI: (event: MouseEvent<HTMLButtonElement>) => void;
  areNotificationsUnread: boolean;
}) => {
  const { palette } = useTheme();

  return (
    <IconButton
      data-testid="notifications-button"
      aria-label="notifications"
      sx={{ color: palette.keyops.blue.dark }}
      onClick={toggleNotificationsUI}
    >
      {areNotificationsUnread ? (
        <Badge data-testid="unread-dot" color="secondary" variant="dot">
          <FiBell data-testid="notification-bell" />
        </Badge>
      ) : (
        <FiBell data-testid="notification-bell" />
      )}
    </IconButton>
  );
};

export default NotificationsButton;
