import React, { useCallback, useState } from 'react';
import { Box, Toolbar, useMediaQuery, useTheme } from '@mui/material';
import { Outlet } from 'react-router';
import { useLocation } from 'react-router-dom';

import DesktopSideNavbar from './components/DesktopSideNavbar';
import CustomAppBar from './components/CustomAppBar';
import MobileSideNavbar from './components/MobileSideNavbar';

const AppLayout = (): JSX.Element => {
  const {
    mixins: { toolbar },
    breakpoints,
    palette,
  } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down('sm'));
  const location = useLocation();
  const isSurveyRoute = location.pathname.startsWith('/engagement/');

  const [openMobileSideNavDrawer, setOpenMobileSideNavDrawer] = useState(false);
  const toggleMobileSideNavDrawer = useCallback(
    (newOpen: boolean) => setOpenMobileSideNavDrawer(newOpen),
    []
  );

  return (
    <Box id="main-box" data-testid="main-box">
      <CustomAppBar toggleMobileSideNavDrawer={toggleMobileSideNavDrawer} />
      <Toolbar />
      <Box
        display="flex"
        gap={2.5}
        height={`calc(100vh - (${toolbar?.minHeight}px + 8px))`}
      >
        {!isMobile && <DesktopSideNavbar />}
        {isMobile && (
          <MobileSideNavbar
            openMobileSideNavDrawer={openMobileSideNavDrawer}
            toggleMobileSideNavDrawer={toggleMobileSideNavDrawer}
          />
        )}
        <Box
          component="main"
          data-testid="main-content"
          flexGrow={1}
          bgcolor={
            isMobile ? palette.keyops.background : palette.keyops.white.main
          }
          borderRadius={!isMobile ? '16px 0px 0px 0px' : 'none'}
          overflow="auto"
          px={!isSurveyRoute && { xs: 2.5, sm: 6, md: 8 }}
          py={!isSurveyRoute && 3}
          sx={{
            transition: 'margin-left 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
          }}
        >
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
};

export default AppLayout;
