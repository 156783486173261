// External Dependencies
import React, { useEffect } from 'react';
import { Stack, useMediaQuery, useTheme } from '@mui/material';
import { useWatch } from 'react-hook-form';

// Internal Dependencies
import KeyOpsTextField from '../components/TextField';
import { FormTitle } from '../styles';
import KeyOpsOptionSingleSelect from '../components/OptionSingleSelect';
import KeyOpsOptionMultiSelect from '../components/OptionMultiSelect';
import {
  BooleanOptions,
  EngagementsOptions,
  FORM_FIELDS,
} from './form-constants';
import { useTranslation } from 'react-i18next';

const formFields = FORM_FIELDS.engageWithKeyops;

export const EngageWithKeyops = ({
  control,
  register,
  trigger,
  setValue,
  errors,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const watchedFields = useWatch({
    control,
    name: ['involvementCommittee', 'smsNotifications'],
  });
  const disablePhoneField = !watchedFields[1];

  useEffect(() => {
    trigger([formFields.mobileNumber.name]);
  }, [watchedFields]);

  return (
    <Stack spacing={!isMobile ? 4 : 3} sx={{ minWidth: 0 }}>
      <FormTitle>{t('onboarding.forms.engageWithKeyops.title')}</FormTitle>
      <KeyOpsOptionMultiSelect
        fieldName={formFields.engagementPreference.name}
        placeholder={formFields.engagementPreference.label}
        defaultValue={[]}
        rules={{
          required: t(`onboarding.forms.fieldErrors.requiredGeneric`),
        }}
        options={EngagementsOptions}
        control={control}
        setValue={setValue}
        errors={errors}
      />
      <KeyOpsOptionSingleSelect
        fieldName={formFields.involvementCommittee.name}
        placeholder={formFields.involvementCommittee.label}
        defaultValue={null}
        rules={{
          validate: (value) => {
            if (value === null || value === undefined) {
              return t(`onboarding.forms.fieldErrors.requiredGeneric`);
            }
            return true;
          },
        }}
        options={BooleanOptions}
        control={control}
        setValue={setValue}
        errors={errors}
      />
      <KeyOpsOptionSingleSelect
        fieldName={formFields.smsNotifications.name}
        placeholder={formFields.smsNotifications.label}
        defaultValue={null}
        rules={{
          validate: (value) => {
            if (value === null || value === undefined) {
              return t(`onboarding.forms.fieldErrors.requiredGeneric`);
            }
            return true;
          },
        }}
        options={BooleanOptions}
        control={control}
        setValue={setValue}
        errors={errors}
      />
      <KeyOpsTextField
        fieldName={formFields.mobileNumber.name}
        placeholder={formFields.mobileNumber.label}
        defaultValue={''}
        disabled={disablePhoneField}
        register={register}
        errors={errors}
      />
    </Stack>
  );
};
