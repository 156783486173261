import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { FEATURE_FLAGS } from '../utils/fe-feature-flags';
import {
  DisplayNotification,
  generateDisplayNotifications,
} from '../utils/functions/notifications-utils';
import { getAllNotifications } from '../api/notifications';
import { EventTypes } from '@keyops-hcp/constants';

interface UseNotificationsResult {
  list: DisplayNotification[];
  setList: Dispatch<SetStateAction<DisplayNotification[]>>;
  unread: boolean;
  setUnread: Dispatch<SetStateAction<boolean>>;
  loading: boolean;
  error: string | null;
}

const useNotifications = (): UseNotificationsResult => {
  const [list, setList] = useState<DisplayNotification[]>([]);
  const [unread, setUnread] = useState(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [notificationError, setNotificationError] = useState<string | null>(
    null
  );

  const fetchNotifications = async () => {
    try {
      const results = await getAllNotifications();
      // Filter notifications
      const filteredResults = results.filter((notification) => {
        if (EventTypes.includes(notification.event.eventType)) return true;
        console.log('event type unrecognized for - ', notification.id);
      });
      const generatedNotifications =
        generateDisplayNotifications(filteredResults);
      setList(generatedNotifications);
      setUnread(
        generatedNotifications.some((notification) => !notification.read)
      );
      setNotificationError(null);
    } catch (error) {
      console.error('error fetching notifications', error);
      setNotificationError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!FEATURE_FLAGS.NOTIFICATIONS_ENABLED) return;

    fetchNotifications();
    const intervalId = setInterval(fetchNotifications, 30000); // Poll every 30 seconds

    return () => clearInterval(intervalId);
  }, []);

  return {
    list,
    setList,
    unread,
    setUnread,
    loading,
    error: notificationError,
  };
};

export default useNotifications;
