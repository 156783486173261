import React from 'react';
import { Video } from '../../components/Video';
import { VideoDto } from '@keyops-hcp/dtos';

export const AdBoard = () => {
  // TODO: replace by fetch 1 video
  const video: VideoDto = {
    id: '1234',
    title: 'Video test 2',
    createdAt: new Date('2024-07-12T15:56:07.034Z'),
    updatedAt: new Date('2024-07-12T15:56:07.034Z'),
    description: 'Video test 2 description',
    vimeoId: '839846619',
    totalRunTime: 120,
  };

  return <Video video={video} />;
};
